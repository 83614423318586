
export class ListQueryOptions {
    constructor(
        public sort?: string,
        public search?: string,
        public offset?: number,
        public limit?: number,
        public total?: boolean
    ) {}
}
