import { NgModule } from '@angular/core';
import { CreateHubComponent } from '@app/main/hub/create-hub/create-hub.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { ManageUserAccessComponent } from '@app/main/hub/manage-user-access/manage-user-access.component';
import { EditThirdPartyIntegrationsComponent } from '@app/main/settings/third-party-integrations/edit-third-party-integrations/edit-third-party-integrations.component';
import { KalturaAccountFormComponent } from '@app/main/settings/third-party-integrations/kaltura-account-form/kaltura-account-form.component';
import { IBMAccountFormComponent } from '@app/main/settings/third-party-integrations/ibm-account-form/ibm-account-form.component';
import { GetThirdPartyIntegrationsComponent } from './third-party-integrations/get-third-party-integrations/get-third-party-integrations.component';
import { LTICredentialsComponent } from './third-party-integrations/lti-credentials/lti-credentials.component';
import { SsoConfigComponent } from './third-party-integrations/sso-config/sso-config.component';
@NgModule({
  declarations: [
    EditThirdPartyIntegrationsComponent,
    IBMAccountFormComponent,
    KalturaAccountFormComponent,
    GetThirdPartyIntegrationsComponent,
    LTICredentialsComponent,
    SsoConfigComponent
  ],
  imports: [SharedModule],
  exports: [
    EditThirdPartyIntegrationsComponent,
    GetThirdPartyIntegrationsComponent
  ],
  entryComponents: [IBMAccountFormComponent, KalturaAccountFormComponent, LTICredentialsComponent]
})
export class SettingsModule {}
