<ng-container>
  <div id="lti-tab-container">
    <mat-tab-group id="lti-tab-group" [(selectedIndex)]="selectedTabIndex">
      <mat-tab label="WONDA Information" color="white">
        <ng-template matTabContent>
          <div class="tab-content">
            <table>
              <tr>
                <td><label>LTI Tool URL</label></td>
                <td><input type="text" [value]="backendURL + '/api/lti13'" readonly style="width: 100%;"></td>
              </tr>
              <tr>
                <td><label>Keyset URL</label></td>
                <td><input type="text" [value]="backendURL + '/api/lti13/keys'" readonly style="width: 100%;"></td>
                </tr>
              <tr>
                <td><label>Login URL</label></td>
                <td><input type="text" [value]="backendURL + '/api/lti13/login'" readonly style="width: 100%;"></td>
              </tr>
              <tr>
                <td><label>Redirection URLs</label></td>
                <td>
                  <textarea readonly rows="3" style="width: 100%;">{{redirectionURLs}}</textarea>
                </td>
              </tr>
              <tr>
                <td><label for="deeplinking_url">Content Selection URL</label></td>
                <td><input id="deeplinking_url" type="text" [value]="backendURL + '/api/lti13'" readonly style="width: 100%;"></td>
              </tr>
            </table>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="LTI Platform Information" color="white">
        <ng-template matTabContent>
          <div class="tab-content">
            <form [formGroup]="lti13Form">
              <table style="width: 100%;">
                <tr>
                  <td><label for="name">Platform Name</label></td>
                  <td><input id="name" formControlName="name" type="text" style="width: 100%;"></td>
                </tr>
                <tr>
                  <td><label for="url">Platform URL</label></td>
                  <td><input id="url" formControlName="url" type="text" style="width: 100%;"></td>
                </tr>
                <tr>
                  <td><label for="clientId">Client Id</label></td>
                  <td><input id="clientId" formControlName="clientId" type="text" style="width: 100%;"></td>
                </tr>
                <tr formGroupName="authConfig">
                  <td> <label for="key">Keyset endpoint</label></td>
                  <td><input id="key" formControlName="key" type="text" style="width: 100%;"></td>
                </tr>
                <tr>
                  <td><label for="accesstokenEndpoint">Access Token Endpoint</label></td>
                  <td><input id="accesstokenEndpoint" formControlName="accesstokenEndpoint" type="text" style="width: 100%;"></td>
                </tr>
                <tr>
                  <td><label for="authenticationEndpoint">Authentication Endpoint</label></td>
                  <td><input id="authenticationEndpoint" formControlName="authenticationEndpoint" type="text" style="width: 100%;"></td>
                </tr>
              </table>
            </form>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="bottom-buttons">
    <button mat-button (click)="close()">Cancel</button>
    <button *ngIf="!createMode" mat-raised-button color="red" (click)="save('remove')"> REMOVE </button>
    <button *ngIf="!createMode" mat-raised-button color="primary" (click)="save('update')"> UPDATE </button>
    <button *ngIf="createMode && selectedTabIndex==0" mat-raised-button color="primary" (click)="next()"> NEXT </button>
    <button *ngIf="createMode && selectedTabIndex==1" mat-raised-button color="primary" (click)="save('add')"> ADD </button>
  </div>
</ng-container>
