import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessmentResult, AssessmentSettings } from '@shared/models';
import { EChartsOption } from 'echarts';
import { AssessmentService } from '@shared/services/assessment.service';

@Component({
  selector: 'assessment-report',
  templateUrl: './assessment-report.component.html',
  styleUrls: ['./assessment-report.component.scss']
})
export class AssessmentReportComponent implements OnInit {
  @Input() dialogMode: boolean;
  @Input() learnerReportMode: boolean;
  @Input() showScoreMedian: boolean;
  @Input() canEdit: boolean;
  @Input() hasClickedAssessmentButton: boolean;
  @Input() courseId: string;
  @Input() courseTitle: string;
  @Input() assessmentSettings?: AssessmentSettings;
  @Input() assessmentResult: AssessmentResult;
  @Output() onShowSessionLogs = new EventEmitter();
  @Output() onRestart = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onOpenAssessmentDashboard = new EventEmitter<boolean>();

  public userName: string;
  public initials: string;
  public score: string;
  public scoreMax: number;
  public chartOption: EChartsOption;

  constructor(
    private _assessmentService: AssessmentService
  ) {}

  ngOnInit(): void {
    this.assessmentResult.report.criterias.forEach((c, index) => c.index = index);

    const criterias = this.assessmentResult.report.criterias.filter(c => !isNaN(c.grade));
    const total = criterias.reduce((acc, c) => acc + c.grade, 0);
    this.scoreMax = 5;
    this.score = (Math.max(1, total / criterias.length)).toFixed(1);
    this.userName = this.getUserName();
    this.initials = this.userName
      .split(/\s+/)
      .map(s => s.length ? s[0].toUpperCase() : '')
      .join('')
      .substring(0, 2);

    this.setupChart();
  }

  private setupChart() {
    let chartColors = ['#45a6b9'];
    let chartData: any[] = [{
      value: this.assessmentResult.report.criterias.map(c => c.grade),
      name: 'Learner Score'
    }];

    if (this.showScoreMedian) {
      chartColors.unshift('#aaaaaa');
      chartData.unshift({
        value: this.assessmentResult.report.criterias.map(c => c.medianGrade),
        name: 'Group median',
        lineStyle: {
          type: 'dashed'
        }
      });
    }

    this.chartOption = {
      legend: this.showScoreMedian ? {
        data: ['Learner Score', 'Group median'],
        orient: 'vertical',
        right: 0,
        bottom: 0,
      }: undefined,
      radar: {
        shape: 'circle',
        axisName: {
          formatter: '{value}',
          color: '#aaa',
          fontSize: 14,
          fontWeight: 'lighter',
          //width: 50,
          overflow: 'truncate'
        },
        splitArea: {
          areaStyle: {
            color: ['rgba(0,0,0,0)'],
          }
        },
        indicator: this.assessmentResult.report.criterias.map(c => {
          return {
            name: c.index != undefined ? `${c.index + 1}. ${c.title}` : c.title,
            max: 5
          }
        })
      },
      series: [
        {
          type: 'radar',
          color: chartColors,
          data: chartData
        }
      ]
    };
  }

  private getUserName() {
    const user = this.assessmentResult.user;
    let parts = [];
    if (user?.first_name) {
      parts.push(user?.first_name);
    }
    if (user?.last_name) {
      parts.push(user?.last_name);
    }
    if (parts.length > 0) {
      return parts.join(' ');
    }
    // Fallback to actor_name
    return this.assessmentResult.session?.actor_name || 'Anonymous';
  }

  downloadReport() {
    this._assessmentService
      .getAssessmentExportDataBySessionId(this.courseId, this.assessmentResult.session_id);
  }

  showSessionLogs() {
    this.onShowSessionLogs.emit();
  }

  openAssessmentEditor() {
    this.onOpenAssessmentDashboard.emit(true);
  }
  
  openAssessmentDashboard() {
    this.onOpenAssessmentDashboard.emit(false);
  }

  closeDialog() {
    this.onClose.emit();
  }

  restart() {
    this.onRestart.emit();
  }

  openHelp() {
    window.open('https://help.spaces.wondavr.com/en/articles/9728097-ai-assessment');
  }
}
