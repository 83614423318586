import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Asset, AssetProviderType, AssetType, User } from '@shared/models';
import { map } from 'rxjs/operators';

@Injectable()
export class IBMService {
  constructor(
    private _http: HttpClient,
  ) {}

  public checkAccount(hubId: string): Observable<any> {
    return this._http.get(
      `${environment.coursesApiUrl}/api/hubs/${hubId}/ibm/account`
    );
  }

  public resetConnection(hubId: string): Observable<any> {
    return this._http.put(`${environment.coursesApiUrl}/api/hubs/${hubId}/ibm/resetConnection`, {}); 
  }
  public findIBMVideo(hubId: string, videoId: string, type: AssetType): Observable<Asset> {
    return this._http
      .get<any>(
        `${environment.coursesApiUrl}/api/hubs/${hubId}/ibm/video/${videoId}`
      )
      .pipe(
        map(ibmVideo => {
          return new Asset(
            type,
            ibmVideo.description || 'ibm vod video',
            null,
            ibmVideo.title,
            null,
            ibmVideo.stream.dhls,
            null,
            ibmVideo.thumbnail.default,
            {
              ibmId: ibmVideo.id,
              isVOD: true,
              duration: ibmVideo.length,
              ibmEndPoint: `${environment.coursesApiUrl}/api/ibm/play`
            },
            false,
            AssetProviderType.ibm
          );
        })
      );
  }

  public findIBMChannel(hubId: string, channelId: string, type: AssetType): Observable<Asset> {
    return this._http
      .get<any>(
        `${environment.coursesApiUrl}/api/hubs/${hubId}/ibm/channel/${channelId}`
      )
      .pipe(
        map(ibmChannel => {
          return new Asset(
            type,
            ibmChannel.description || 'ibm live channel',
            null,
            ibmChannel.title,
            null,
            ibmChannel.stream.dhls,
            null,
            ibmChannel.thumbnail.live,
            {
              ibmId: ibmChannel.id,
              isLive: true,
              ibmEndPoint: `${environment.coursesApiUrl}/api/ibm/play`
            },
            false,
            AssetProviderType.ibm
          );
        })
      );
  }
}
