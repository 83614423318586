import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from '@env/environment';
import { LTIPlatform } from '@shared/models';
import { FormControl, FormGroup } from '@angular/forms';

type CommitChangesAction = (platform: LTIPlatform, action: string) => Promise<void>;

@Component({
  selector: 'lti-credentials-platfom-settings',
  templateUrl: './lti-credentials-platfom-settings.component.html',
  styleUrls: ['./lti-credentials-platfom-settings.component.scss']
})
export class LTICredentialsPlatformSettingsComponent {
  private platform?: LTIPlatform;
  private lti13Form: FormGroup;
  private backendURL: string;
  public redirectionURLs: string;
  public createMode: boolean;
  public selectedTabIndex: number;
  private commitChanges: CommitChangesAction;

  constructor(
    private _overlayContainer: OverlayContainer,
    public dialogRef: MatDialogRef<LTICredentialsPlatformSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { platform?: LTIPlatform, commitChanges: CommitChangesAction }
  ) {
    this.platform = data.platform;
    this.commitChanges = data.commitChanges;
    this.createMode = !this.platform;
    this.selectedTabIndex = this.createMode ? 0 : 1;
    this.backendURL = environment.coursesApiUrl;
    this.redirectionURLs = ['', '/login', '/deeplink'].map(s => `${this.backendURL}/api/lti13${s}`).join('\n');

    this.lti13Form = new FormGroup({
      url: new FormControl(this.platform?.url || ''),
      name: new FormControl(this.platform?.name || ''),
      clientId: new FormControl(this.platform?.clientId || ''),
      authenticationEndpoint: new FormControl(this.platform?.authenticationEndpoint || ''),
      accesstokenEndpoint: new FormControl(this.platform?.accesstokenEndpoint || ''),
      authConfig: new FormGroup({
        key: new FormControl(this.platform?.authConfig.key || '')
      })
    });

    this._overlayContainer.getContainerElement().classList.add('learnspace-theme');
    this._overlayContainer.getContainerElement().classList.remove('editor-theme');
  }

  save(method: string): void {
    const platform: LTIPlatform = { ...this.lti13Form.value }
    platform.authConfig.method = 'JWK_SET';

    this.commitChanges(platform, method)
      .then(this.close.bind(this));
  }

  next(): void {
    this.selectedTabIndex = 1;
  }

  close(): void {
    this.dialogRef.close();
  }
}
