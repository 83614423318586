<div id="login" class="learnspace-theme">

  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="header">
      <div class="welcome">Welcome to</div>
      <div class="logo">
        <img src="assets/wonda/logo-wonda.png">
      </div>
    </div>

    <div class="stepper-container">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step>
          <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
            <div class="title">{{ loginMessage }}</div>
            <form name="loginForm" [formGroup]="loginForm" novalidate>
              <mat-form-field>
                <input matInput placeholder="Email" formControlName="email">
                <mat-error *ngIf="loginForm.get('email').hasError('required')">
                  Email is required
                </mat-error>
                <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <input matInput type="password" placeholder="Password" formControlName="password">
                <mat-error>
                  Password is required
                </mat-error>
              </mat-form-field>

              <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                <mat-checkbox class="remember-me" aria-label="Remember Me">
                  Remember Me
                </mat-checkbox>
              </div>

              <div *ngIf="loginError" style="color:black; text-align: center">{{ loginError }}</div>
              <button (click)="mailLogin()" mat-raised-button color="primary" class="login-button submit-button" aria-label="LOG IN" [disabled]="loginForm.invalid">
                LOGIN
              </button>

              <div style="text-align:center">OR</div>
              <button mat-raised-button class="submit-button" (click)="quickLogin('google')">
                <img style="height:20px; width: 20px; margin:4px" src="assets/wonda/google-logo.png"> LOGIN WITH GOOGLE
              </button>
              <button mat-raised-button class="submit-button" (click)="quickLogin('microsoft')">
                <img style="height:20px; width: 20px; margin:4px" src="assets/wonda/microsoft-logo.png"> LOGIN WITH MICROSOFT
              </button>
              <button (click)="ssoFlow(stepper)" mat-raised-button class="submit-button">
                LOGIN WITH SSO
              </button>
            </form>
            <div style="cursor: pointer; margin-top: 32px; margin-bottom: 8px; color: #6b6b6b" (click)="resetPassword()">
              Forgot your Password?
            </div>
            <a class="blue-text" [routerLink]="['/signup']">
              Register
            </a>
          </div>
        </mat-step>
        <mat-step>
          <search-hub-by-domain></search-hub-by-domain>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>

</div>