<div>
  <p id="infotext">
    Easily integrate Wonda experiences in your LMS (Moodle, Canvas, Blackboard & more) with the LTI integration.
  </p>
  <div id="lti-config" *ngIf="hub">
    <div class="form-fields">
      <span>
        <mat-checkbox [checked]="hub.lti_enabled" (change)="updateLTIStatus($event)"> Enable LTI </mat-checkbox>
        <mat-icon (click)="openHelp()" matTooltip="Open Help Center" matTooltipShowDelay="500">help</mat-icon>
      </span>
      <ng-container *ngIf="hub.lti_enabled">
        <div id="lti-tab-container">
          <mat-tab-group id="lti-tab-group">
            <mat-tab label="LTI 1.3 (Beta)" color="white">
              <ng-template matTabContent>
                <div class="tab-content">
                  <table #table mat-table *ngIf="lti_platforms" [dataSource]="lti_platforms">
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef> Name </th>
                      <td mat-cell *matCellDef="let platform"> {{platform.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> Actions </th>
                      <td mat-cell *matCellDef="let platform">
                        <mat-icon class="action-icon" (click)="editPlatform(platform)">edit</mat-icon>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="ltiTableColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: ltiTableColumns;"></tr>
                  </table>
                  <div class="bottom-buttons">
                    <button mat-raised-button color="primary" aria-label="ADD" [disabled]="!canAddLTIPlatform" (click)="editPlatform(null)" [matTooltip]="getLTIButtonTooltip()" matTooltipShowDelay="500">
                      Add New LTI 1.3 Platform
                    </button>
                  </div>
                </div>
              </ng-template>
            </mat-tab>
            <mat-tab label="LTI 1.1" color="white">
              <ng-template matTabContent>
                <div class="tab-content">
                  <div class="config-item">
                    <label for="consumer_key">LTI Consumer Key</label>
                    <input id="consumer_key" type="text" [value]="hub.lti_consumer_key" readonly [disabled]="!hub.lti_enabled">
                  </div>
                  <div class="config-item">
                    <label for="consumer_secret">LTI Consumer Secret</label>
                    <input id="consumer_secret" type="text" [value]="hub.lti_consumer_secret" readonly [disabled]="!hub.lti_enabled">
                  </div>
                  <div class="config-item">
                    <label for="configuration_url">LTI Configuration URL</label>
                    <input id="configuration_url" type="text" [value]="backendURL + '/api/lti/config'" readonly [disabled]="!hub.lti_enabled">
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-container>
    </div>
  </div>
</div>