<div class="title">
  <div class="mat-subheading-2">
    {{ _data ? _data.title : '' }}
  </div>
  <mat-icon (click)="onClose()">clear</mat-icon>
</div>
<div class="card-content">
  <!-- Warn : order is important -->
  <ng-container *ngIf="openOnTab !== -1; else noTab">
    <mat-tab-group #fileManagerTabGroup [selectedIndex]="openOnTab">
      <mat-tab label="All my media">
        <ng-template matTabContent>
          <p class="mat-caption filter-hint">
            All media you have uploaded in all experiences
          </p>
          <file-drag-drop
            [mode]="'u_media'"
            [type]="type"
            [courseId]="courseId"
            (selected)="onCurrentSelect($event)"
          ></file-drag-drop>
        </ng-template>
      </mat-tab>
      <mat-tab label="From this space">
        <ng-template matTabContent>
          <p class="mat-caption filter-hint">
            All media from this space, including those uploaded by other
            participants
          </p>
          <file-drag-drop
            [mode]="'l_media'"
            [type]="type"
            [courseId]="courseId"
            (selected)="onCurrentSelect($event)"
          ></file-drag-drop>
        </ng-template>
      </mat-tab>
      <mat-tab label="From this experience">
        <ng-template matTabContent>
          <p class="mat-caption filter-hint">
            All media from this experience, including those uploaded by other
            participants
          </p>
          <file-drag-drop
            [mode]="'c_media'"
            [type]="type"
            [courseId]="courseId"
            (selected)="onCurrentSelect($event)"
          ></file-drag-drop>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="kalturaTab" label="From Kaltura">
        <ng-template matTabContent>
            <p class="mat-caption filter-hint">
                All media from your Kaltura Library <mat-icon (click)="goToHelp()" class="help-link">help</mat-icon>
              </p>
          <file-drag-drop
            [mode]="'k_media'"
            [type]="type"
            [courseId]="courseId"
            [externalProvider]="'Kaltura'"
            (selected)="onCurrentSelect($event)"
          ></file-drag-drop>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="readyPlayerMeTab" label="From Ready Player Me">
        <ng-template matTabContent>
          <ready-player-me-link
            [type]="type"
            (selected)="onCurrentSelect($event)"
          ></ready-player-me-link>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="ibmTab" label="From IBM">
        <ng-template matTabContent>
          <ibm-selector
            [type]="type"
            (selected)="onCurrentSelect($event)"
          ></ibm-selector>
        </ng-template>
      </mat-tab>
      <!-- <mat-tab *ngIf="isVideo" label="From Youtube (Beta)">
        <ng-template matTabContent>
          <youtube-selector
            [type]="type"
            (selected)="onCurrentSelect($event)"
          ></youtube-selector>
        </ng-template>
      </mat-tab> -->
      <mat-tab *ngIf="isVideo && canAddLink" label="From live source">
        <ng-template matTabContent>
          <live-link
          [type]="type"
          (selected)="onCurrentSelect($event)"
          ></live-link>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-template #noTab>
    <file-drag-drop
      [mode]="'u_media'"
      [type]="type"
      [courseId]="courseId"
      (selected)="onCurrentSelect($event)"
    ></file-drag-drop>
  </ng-template>
</div>
