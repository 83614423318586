import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LinkService {
    constructor(
        private _http: HttpClient,
    ) { }

    /**
     *  Users API
     */


    public launchCourse(course_id: string, isMulti = false, openNewTab: boolean = true, showPoster = false, enableVersioning = false, enableSaveProgression = false): void{
        // Every launch from editor should have presenter mode
        let conditionalQueryParams = `${(isMulti) ? '&presenter=true' : ''}`;
        conditionalQueryParams += `${(enableVersioning) ? '&versioned=false' : ''}`;
        conditionalQueryParams += `${(enableSaveProgression) ? '&instanced=false' : ''}`;
        const link = `${environment.coursesApiUrl}/api/courses/${course_id}/room?poster=${showPoster}${conditionalQueryParams}`;
        if (openNewTab) {
            window.open(link);
        }
        else {
            window.location.assign(link);
        }
    }
}
