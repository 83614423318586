export class CourseRelease {
  constructor(
    public id: string,
    public version: string,
    public url: string,
    public createdAt?: Date,
    public updatedAt?: Date,
  ) { }


  static IncrementVersion(versionString: string, shouldIncrementMajor = false ): string {
    const versionNumbers = versionString.split('.');
    if (versionNumbers.length !== 2) {
      throw new Error('incrementMinorVersion - unexpected input version: ' + versionString);
    }

    const major = parseInt(versionNumbers[0], 10);
    if (shouldIncrementMajor) {
      const incrementMajor = major + 1;
      return `${incrementMajor}.0`;
    } else {
      const incrementedMinor = parseInt(versionNumbers[1], 10) + 1;
      return `${major}.${incrementedMinor}`;
    }
  }
}
