import { SessionData } from '@shared/services/analytics.service';
import { Section, Asset, CourseRelease, User } from '@shared/models';
import { Learnspace } from '@shared/models/learnspace.model';
export enum ExperienceType {
  mixed = 'mixed',
  only360 = '360'
}
export interface MainDisplayProperties{
  position: { x: number, y: number, z: number },
  rotation: { x: number, y: number, z: number },
  scale: { x: number, y: number, z: number },
  visible: boolean,
  titleEnabled: boolean
}
export class Course {
  constructor(
    public title: string,
    public description: string,
    public editing_user_id: string,
    public user_id: string,
    public sections: Section[],
    public learnspace_id: string,
    public id?: string,
    public status?: string,
    public postermedia_id?: string,
    public postermedia?: Asset,
    public learnspace?: Learnspace,
    public courseReleases?: CourseRelease[],
    public information?: {
      about: string, links: string[],
      backlightActivatedPerDefault: boolean,
      backlightEnabled: boolean,
      displayArrow: boolean,
      displayOutline: boolean,
      mainDisplayScaleFactor?: number,
      mainDisplayProperties?: MainDisplayProperties,
      displayPoster: boolean,
      scoringEnabled: boolean,
      passingScore: number,
      scoreAlertEnabled: boolean,
      scoreAlertMessage: string,
      skyboxRotationYAxis?: number,
      lockNavigationEnabled: boolean,
      ltiGradeReport: string,
      ltiMaxScore: string,
      trackingEnabled: boolean,
      completionScene: string,
      completionAlertEnabled: boolean,
      completionAlertMessage: string,
      mainDisplayEnabled: boolean, //TODO clean data model duplicate with mainDisplayProperties.visible
      mainDisplayTitleEnabled: boolean, //TODO clean data model duplicate with mainDisplayProperties.titleEnabled
      versioningEnabled: boolean,
      saveProgressionEnabled: boolean,
      displayAIAgentSettingsEnabled: boolean,
      editSkyboxEnabled: boolean,
      externalXAPI: {
        endpoint: string,
        key: string,
        secret: string
      },
      deviceEnabled: {
        desktop: boolean,
        mobile: boolean,
        headset: boolean
      },
      assessment?: AssessmentSettings, // TODO: Rename variable
    },
    public editing_user?: any,
    public owner?: any,
    public course_room?: Asset,
    public model_id?: string,
    public course_model_3D?: Asset,
    public skybox_id?: string,
    public course_skybox?: Asset,
    public public_status?: string,
    public createdAt?: string,
    public updatedAt?: string,
    public is_multi?: boolean,
    public short_code?: string,
    public experience_type?: ExperienceType,
    public dynamicAnnotationCount?: number
  ) { }
}

export class AssessmentSettings {
  constructor(
    public title: string,
    public description: string,
    public criterias: AssessmentCriteria[],
    public learnerReportEnabled?: boolean
  ) {}
}

export class AssessmentCriteria {
  constructor(
    public id: number,
    public title: string,
    public description: string
  ) {}
}

export class AssessmentResult {
  constructor(
    public session_id: string,
    public report: AssessmentReport,
    public createdAt: Date,
    public session?: SessionData,
    public user?: User
  ) {}
}

export class AssessmentReport {
  constructor(
    public evaluationSummary: string,
    public recommendations: string,
    public criterias: AssessmentCriteriaResult[],
    public id?: string,
    public session?: any
  ) {}
}

export class AssessmentCriteriaResult {
  constructor(
    public title: string,
    public insight: string,
    public grade: number,
    public medianGrade?: number,
    public index?: number
  ) {}
}
