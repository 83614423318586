<div class="form-container">
    <div class="layout">
      <div class="intro">
        <img src="assets/ibm/ibm-logo-black.png" />
        <p>
          Please fill in the information below to authorize Wonda to access your IBM videos.<br />
          <a href="mailto:support@wondavr.com">Contact us</a> to get information about IBM Video integration.
        </p>
      </div>
      <div *ngIf="accountMsg" style="color:green">
        {{ accountMsg }}
      </div>
      <div *ngIf="errorMsg" class="error-msg">
        {{ errorMsg }}
      </div>
      <form [formGroup]="ibmAccountForm">
        <div class="ibm-form-fields">
          <mat-form-field>
            <input matInput placeholder="Client id" formControlName="clientId">
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Secret" formControlName="secret">
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="confirm-button">
      <button mat-raised-button color="primary" aria-label="CONFIRM" (click)="updateIBMAccount()">
        CONFIRM
      </button>
    </div>
  </div>