import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: any): void {
    console.error(error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const unexpectedToken = /Unexpected token '<'/;
    if (chunkFailedMessage.test(error.message) || unexpectedToken.test(error.message)) {
      window.location.reload();
    }
  }
}