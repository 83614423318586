<div id="wrapper" fxLayout="column" fxLayoutAlign="space-evenly center" class="wrapper learnspace-theme">
  <div id="title">
    <img src="assets/wonda/logo-wonda.png">
    <span class="title small">Your Social Learning XR Platform</span>
  </div>
  <div id="main-section">
    <div id="left-area">
      <span class="title">Discover New Experiences</span>
      <div *ngIf="this.courses" id="experience-list">
        <experience-card *ngFor="let course of courses" [course]="course"></experience-card>
      </div>
    </div>
    <div id="right-area">
      <span *ngIf="this.hubs"class="title">Access to your Hub</span>
      <div *ngIf="this.hubs" id="hubs-list">
          <ng-container *ngFor="let hub of hubs">
            <div class="hub-button" (click)="switchHub(hub)">
                <img class="hub-icon" src="{{hub.logo?.path}}" alt="{{hub.title}}">
                <ng-container *ngIf="isAdminOfHub(hub.id)">
                    <mat-icon class="mat-icon-right" (click)="editHub(hub)" >settings</mat-icon>
                </ng-container>
            </div>
          </ng-container>
        </div>
    </div>
  </div>
</div>
