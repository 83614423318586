import { v4 as uuid } from 'uuid';
export enum LinkType {
    scene = 'scene',
    experience = 'experience',
    url = 'url',
    lti_return = 'lti_return'
  }
export enum ToggleElementType {
    toggle = 'toggle',
    show = 'show',
    hide = 'hide'
  }

export enum ActionType {
    link = 'link',
    sound = 'sound',
    triggerElm = 'triggerElm'
}
export class AnnotationAction {
    constructor(
        public id?: string,
        public scoring_enabled?: boolean,
        public scoring_value?: number,
        public effects?: AnnotationActionEffect[]

    ) {

        this.id = uuid()
        this.effects = effects || [];
    }
}

export class AnnotationActionEffect{
    constructor(
        public type?: ActionType,
        public target_type?: LinkType,
        public target_value?: string,
        public target_new_tab?: boolean,
        public card_id?: string,
        public sound_url?: string,
        public sound_name?: string,
        public volume?: number,
        public seekToSceneCurrentTime?: boolean,
        public triggerElmMode?: ToggleElementType,
        public expanded?: boolean
    ) {

    }
}

export function getDefaultAction(): AnnotationAction{
    return new AnnotationAction(null, false, 0, [])
}