import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, first } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from '@shared/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { PasswordResetComponent } from '@app/main/login/password-reset/password-reset.component';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginError: string = null;
  loginMessage = 'LOGIN TO YOUR ACCOUNT';

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      // If login page is called with a redirect URL, add the redirect outer address to authservice
      if (params['redirect']) {
        this.loginMessage = 'Please Login to\nAccess this Experience';
        this._authService.redirectOuter = decodeURIComponent(
          params['redirect']
        );
      }
      // If user is logged already, automatically redirect
      this._authService._fireAuth.authState.pipe(first()).subscribe(user => {
        if (user) {
          this._authService.afterLoginRedirect();
        }
      });
    });
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  public mailLogin(): void {
    this._authService
      .mailLogin(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value
      )
      .subscribe(null, err => {
        this.loginError = err.message;
        this._changeDetector.detectChanges();
      });
  }

  public quickLogin(provider: string): void {
    this._authService.quickLogin(provider, false).subscribe(null, err => {
      if (err.message === 'No account found') {
        this.loginError = 'Your account is not associated to a Hub, please ask the Hub admin for an invitation';
      } else {
        this.loginError = err.message;
      }
      this._changeDetector.detectChanges();
    });
  }

  public ssoFlow(stepper: MatStepper): void {
    stepper.next();
  }

  public resetPassword(): void {
    this._dialog
      .open(PasswordResetComponent, {
        height: '384px',
        width: '330px',
        panelClass: 'learnspace-theme',
        data: { email: this.loginForm.controls.email.value }
      })
      .afterClosed()
      .pipe(filter(a => a))
      .subscribe();
  }
}
