<div>
  <p class="mat-caption filter-hint">
    Create and import a custom full-body avatar using <a href="https://readyplayer.me/avatar" target=”_blank”>Ready Player Me </a>      <mat-icon (click)="goToHelp()" class="help-link">help</mat-icon>
  </p>
  <div class="searchInput">
    <mat-form-field class="url-input">
      <input #textSearch matInput placeholder="Paste your avatar url here (.glb)">
    </mat-form-field>
    <button mat-raised-button color="primary" [disabled]="!canSave()" (click)="validatePathAndCreateMedia(textSearch.value)">Import Avatar</button>
  </div>
  <ng-container *ngIf="pathError;">
    <div class="path-error">There was an error when adding the media from ReadyPlayerMe<br>{{pathError.message}}</div>
  </ng-container>
  <assets-list
    [mode]="'rpm_media'"
    [type]="type"
    (selected)="onCurrentSelect($event)"
  >
  </assets-list>
</div>