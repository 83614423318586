import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { NgxDnDModule } from '@swimlane/ngx-dnd';

import { WondaComponent } from '@app/main/wonda/wonda.component';
import { AuthGuard } from '@app/guards/auth.guard';
import { InvitationModule } from '@app/main/invitation/invitation.module';
import { InvitationComponent } from '@app/main/invitation/invitation.component';
import { AccountEditorModule } from '@app/account-editor/account-editor.module';
import { AccountEditorComponent } from '@app/account-editor/account-editor.component';
import { HubEditorModule } from '@app/main/hub/hub-edit/hub-edit.module';
import { HubEditComponent } from '@app/main/hub/hub-edit/hub-edit.component';
import { ErrorComponent } from '@app/main/error-page/error.component';
import { ErrorModule } from '@app/main/error-page/error.module';
import { CourseViewModule } from '@app/main/apps/course-view/course-view.module';
import { HubResolver } from '@app/hub-resolver/hub-resolver';
import { MetahomeModule } from '@app/main/metahome/metahome.module';
import { MetahomeComponent } from '@app/main/metahome/metahome.component';
import { LearnerReportModule } from '../learner-report/learner-report.module';
import { LearnerReportComponent } from '@app/main/learner-report/learner-report.component';

const routes = [
  {
    path: 'account', component: AccountEditorComponent, canActivate: [AuthGuard]
  },
  {
    path: 'hubedit/:urlTitle', component: HubEditComponent, canActivate: [AuthGuard], resolve: { hub: HubResolver }
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'discover',
    canActivate: [AuthGuard],
    component: MetahomeComponent
  },
  {
    path: 'reports/courses/:courseId/sessions/:sessionId',
    component: LearnerReportComponent
  },
  {
    path: ':urlTitle/:lsId/analytics', redirectTo: 'analytics/:urlTitle/:lsId'
  },
  {
    path: ':urlTitle/:lsId/courses/:courseId/analytics', redirectTo: 'analytics/:urlTitle/:lsId/courses/:courseId'
  },
  {
    path: 'analytics/:urlTitle',
    canActivate: [AuthGuard], // TODO guard
    loadChildren: () => import('@app/main/analytics/analytics.module').then(m => m.AnalyticsModule),
    resolve: {
      hub: HubResolver
    }
  },
  {
    path: ':urlTitle/:lsId/courses',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/main/apps/course-detail/course-detail.module').then(m => m.CourseDetailModule),
    resolve: { hub: HubResolver }
  },
  {
    path: 'invitations/:id',
    component: InvitationComponent
  },
  {
    path: ':urlTitle',
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/main/learnspaces/learnspace.module').then(m => m.LearnspaceModule),
    resolve: {
      hub: HubResolver
    }
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: WondaComponent
  }
];

@NgModule({
  declarations: [WondaComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    NgxDnDModule,
    CourseViewModule,
    InvitationModule,
    LearnerReportModule,
    AccountEditorModule,
    ErrorModule,
    HubEditorModule,
    MetahomeModule
  ],
  exports: [WondaComponent]
})
export class WondaModule { }
