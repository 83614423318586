export enum AnnotationType {
  image = 'image',
  video = 'video',
  text = 'text',
  hotspot = 'hotspot',
  card = 'card',
  scorecard = 'scorecard',
  quiz = 'quiz',
  object3D = 'object3D',
  snapZone = 'snapZone',
  npc = 'npc'
}

export const typeData = new Map<AnnotationType, { iconName: string, displayName: string, svgIcon: boolean }>([
  [AnnotationType.image, { iconName: 'add-image', displayName: 'Image', svgIcon: true }],
  [AnnotationType.video, { iconName: 'add-video', displayName: 'Video', svgIcon: true }],
  [AnnotationType.text, { iconName: 'add-text', displayName: 'Text', svgIcon: true }],
  [AnnotationType.hotspot, { iconName: 'add-hotspot', displayName: 'Hotspot', svgIcon: true }],
  [AnnotationType.card, { iconName: 'add-card', displayName: 'Card', svgIcon: true }],
  [AnnotationType.quiz, { iconName: 'add-quiz', displayName: 'Quiz', svgIcon: true}],
  [AnnotationType.scorecard, { iconName: 'add-scorecard', displayName: 'Score Card', svgIcon: true }],
  [AnnotationType.object3D, { iconName: 'add-3D', displayName: '3D model', svgIcon: true}],
  [AnnotationType.snapZone, { iconName: 'add-snap-zone', displayName: 'Snap Zone', svgIcon: true}],
  [AnnotationType.npc, { iconName: 'add-npc', displayName: 'AI Character', svgIcon: true}]
]);

export function getTypeData(): AnnotationType[] {
  return Array.from(typeData.keys());

}
