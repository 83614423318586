<div class="cmp-container learnspace-theme">
    <div class="warning">
        <h3 style="color: black">Delete my account</h3>
        <div>Are you sure you want to delete your account ?<br>All your data will be lost.</div>
    </div>

    <div mat-dialog-actions class="buttons">
        <button mat-button (click)="onClose()">Cancel</button>
        <button mat-raised-button color="primary" (click)="onDelete()">YES</button>
    </div>
</div>