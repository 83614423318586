<div id="login" class="learnspace-theme">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div class="header signUp-login">
      <div class="welcome">Welcome to</div>
      <div class="logo">
        <img src="assets/wonda/logo-wonda.png">
      </div>
    </div>
    <div class="stepper-container">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step>
          <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
            <div class="title">Let's get started: </div>
            <form name="signUpForm" [formGroup]="signUpForm" novalidate>
              <mat-form-field>
                <input matInput placeholder="Name" formControlName="first_name">
                <mat-error *ngIf="signUpForm.get('first_name').hasError('required')">
                  Name is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Email" formControlName="email">
                <mat-error *ngIf="signUpForm.get('email').hasError('required')">
                  Email is required
                </mat-error>
                <mat-error *ngIf="!signUpForm.get('email').hasError('required') &&
                                signUpForm.get('email').hasError('email')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <input matInput type="password" placeholder="Password" formControlName="password">
                <mat-error>
                  Password is required
                </mat-error>
                <!-- TODO password confirmation -->
              </mat-form-field>
              <!-- <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Remember Me
                    </mat-checkbox>
                </div> -->
              <div *ngIf="signUpError" style="color:red;">{{ signUpError}}</div>
              <button mat-raised-button color="primary" class="submit-button" aria-label="LOG IN" [disabled]="signUpForm.invalid" (click)="createUser()">
                CREATE AN ACCOUNT
              </button>
              <div style="text-align:center">OR</div>
              <button mat-raised-button class="submit-button" (click)="quickLogin('google')">
                <img style="height:20px; width: 20px; margin:4px" src="assets/wonda/google-logo.png"> REGISTER WITH GOOGLE
              </button>
              <button mat-raised-button class="submit-button" (click)="quickLogin('microsoft')">
                <img style="height:20px; width: 20px; margin:4px" src="assets/wonda/microsoft-logo.png"> REGISTER WITH MICROSOFT
              </button>
              <button (click)="ssoFlow(stepper)" mat-raised-button class="submit-button">
                REGISTER WITH SSO
              </button>
            </form>
            <a class="blue-text" [routerLink]="['/login']">
              Already registered? Log in!
            </a>
          </div>
        </mat-step>
        <mat-step>
          <search-hub-by-domain [isSignUp]="true"></search-hub-by-domain>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>