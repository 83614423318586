import { Scene, Asset } from '@shared/models';
import { Status } from '@shared/models/status.model';

export class Section {
    constructor(
        public title: string,
        public course_id: string,
        public status?: Status,
        public priority?: number,
        public id?: string,
        public scenes?: Scene[],
        public properties?: {
            volume?: number;
            ambientsound_playonce?: boolean
        },
        public sound_id?: string,
        public sound?: Asset,
    ) {
        this.properties = {
            volume: 100,
            ambientsound_playonce: false
        };
    }
}
