<mat-card (click)="launchCourse()">
  <!-- Floating hub logo here -->
  <div class="img-container">
    <div class="hub-logo-container" >
        <img class="hub-logo" *ngIf="course.learnspace.hub.logo" [src]="course.learnspace.hub.logo?.path" alt="">
    </div> 
    <img mat-card-image [src]="course.postermedia?.path" alt="">
  </div>
    <mat-card-content>
        <p class="bold">{{ course.title }}</p>
        <p class="description">{{ course.description }}</p>
        <p>Last updated on {{ course.updatedAt | date }}</p>
    </mat-card-content>
  </mat-card>