export const environment = {
    production: false,
    hmr       : false,
    version: require('../../package.json').version,
    roomEditorUrl: 'https://localhost:8081',
    coursesApiUrl: 'http://localhost:8082',
    coursesSocketUrl: 'wss://localhost:8445/websocket',
    assetManagerUrl: 'https://dev-assets-manager.spaces.wondavr.com',
    contentBucketPrefix: 'https://dev-content.spaces.wondavr.com',
    assetsBucketPrefix: 'https://dev-assets.spaces.wondavr.com',
    roomUrl: 'https://localhost:8080',
    cookieSubdomain: 'localhost',
    firebase: {
      apiKey: 'AIzaSyA5X0cKNunvgUlPm085Cr_uJvVEhPLhtIU',
      authDomain: 'dev-spaces.firebaseapp.com',
      databaseURL: 'https://dev-spaces.firebaseio.com',
      projectId: 'dev-spaces', 
      storageBucket: 'dev-spaces.appspot.com',
      messagingSenderId: '851772332292'
    },
    quizDefaultImageId: 'a3302f31-e764-11e8-97fb-cf3ae52fdd00',
    intercom_app_id: 'z0vfygyg',
    heap_id: '',
    welcomeMessageUrl: 'https://wondavr.com/welcome/welcome.html',
    imlabHubId: 'e6f02ee0-7883-11e9-b100-e5591d5740a0'
};
