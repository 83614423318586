import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { SvgIconService } from 'app/shared/services/svgIcons.service';
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseSplashScreenService: FuseSplashScreenService, // import mandatory otherwise splash screen never disappear
    private _platform: Platform,
    private iconService: SvgIconService
  ) {
    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }
    this.iconService.registerIcons();
  }
}
