import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Hub } from '@shared/models';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'search-hub-by-domain',
  templateUrl: './search-hub-by-domain.component.html',
  styleUrls: ['./search-hub-by-domain.component.scss']
})
export class SearchHubByDomainComponent implements OnInit, AfterViewInit {
  @ViewChild('emailInput') public emailInput: ElementRef;

  @Input() isSignUp = false;

  noHubFound = false;
  otherError = false
  hubList: Hub[];
  selectedHubId: string;
  goBackMessage = "Back to login"
  constructor(
    private _courseApiService: CoursesAPIService,
    private _authService: AuthenticationService,
    private _overlayContainer: OverlayContainer,
  ) {
    this._overlayContainer.getContainerElement().classList.add('learnspace-theme');
    this._overlayContainer.getContainerElement().classList.remove('editor-theme');
  }

  ngOnInit(): void {
    if (this.isSignUp) {
      this.goBackMessage = "Back to sign up"
    }
  }

  ngAfterViewInit(): void {
    this.emailInput.nativeElement.value = localStorage.getItem('email');
  }

  resetSearch(): void {
    this.noHubFound = false;
    this.otherError = false;
    this.hubList = undefined;
    this.selectedHubId = undefined;
  }
  getSsoAndLog(): void {
    try {
      if (this.selectedHubId) {
        this._authService
          .ssoLogin(this.selectedHubId);
      } else if (this.emailInput.nativeElement.value) {
        localStorage.setItem('email', this.emailInput.nativeElement.value);
        const domain = this.emailInput.nativeElement.value.split('@')[1];
        this._courseApiService
          .searchHubBySsoDomain(domain)
          .subscribe(hubList => {
            if (hubList.length === 0) {
              this.noHubFound = true;
              this.otherError = false;
            } else if (hubList.length > 1) {
              this.hubList = hubList;
              this.selectedHubId = hubList[0].id;
            } else {
              this._authService.ssoLogin(hubList[0].id);
            }
          });
      }
    } catch(e) {
      console.error(e);
      this.otherError = true;
    }
  }

}
