<div
  ngFileDrop
  (uploadOutput)="onUploadOutput($event)"
  [ngClass]="{ 'is-drop-over': dragOver === true }"
  [options]="options"
  class="tab-content"
>
  <div class="on-drop">
    <p>
      Drop your files here
    </p>
  </div>
  <div class="content">
    <assets-list
      [mode]="mode"
      [type]="type"
      (selected)="onCurrentSelect($event)"
      class="asset-list"
    ></assets-list>
    <div class="drag">
      <label class="upload-button">
        <p *ngIf="rejectedFileErrorMsg; else uploadMsg" class="error-msg">
          {{ rejectedFileErrorMsg }}
        </p>
        <ng-template #uploadMsg>
          <ng-container [ngSwitch]="uploadState">
            <ng-container *ngSwitchCase="'idle'">
              <ng-container *ngIf="isKaltura(); else default">
                <div class="kaltura-drag-msg">
                  <p>Drag & drop to upload files in your Kaltura gallery</p>
                </div>
              </ng-container>
              <ng-template #default>
                <p>Drag & drop your files here</p>
              </ng-template>
            </ng-container>
            <p *ngSwitchCase="'finishing'" class="uploaded">
              {{ uploadedMsg }}
            </p>
            <ng-container *ngSwitchDefault>
              <p>
                Uploading file {{ fileIndex + 1 }}/{{ filesToUpload.length }}
                <span *ngIf="progress | async as p; else filename"
                  >{{ p.name }} ({{ p.progress | percent }})</span
                >
                <ng-template #filename>
                  {{ filesToUpload[fileIndex].name }}
                </ng-template>
              </p>
            </ng-container>
          </ng-container>
        </ng-template>
        <input
          type="file"
          ngFileSelect
          (uploadOutput)="onUploadOutput($event)"
          [uploadInput]="uploadInput"
          [options]="options"
          multiple
        />
      </label>
    </div>
  </div>
</div>

<!-- Not rendered but kept here for debug-->
<div class="forThumbnail">
  <canvas #canvasForThumbnail></canvas>
</div>
