import { Hub, InvitationType, Learnspace, User} from '@shared/models';

export class Invitation {
  constructor(
    public sender_id: string,
    public recipient_mail?: string,
    public target_id?: string,
    public type?: InvitationType,
    public options?: any,
    public recipient_id?: string,
    public activated_at?: string,
    public path?: string,
    public id?: string,
    public sender?: User,
    public learnspace?: Learnspace,
    public hub?: Hub
  ) { }
}
