import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Asset, AssetProviderType } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
@Injectable()
export class YoutubeService {
  
  constructor(
    private _http: HttpClient,
    private _courseService: CoursesAPIService
  ) {}

  public findYoutubeVideo(link: string): Observable<any>{
    return this._http.post(
      environment.assetManagerUrl + '/youtube/info',
      {
        url: link
      }
    );
  }

  public createYoutubeMedia(data: any): Observable<Asset>{
    const path = environment.assetManagerUrl + '/youtube/stream?url=' + encodeURIComponent(data.video_url);
    const media = new Asset(data.type,
      data.description,
      null,
      data.title,
      null,
      path,
      null,
      data.thumbnail_url,
      { duration: data.length_seconds },
      false,
      AssetProviderType.youtube
      );
     return this._courseService.createMedia(media);
  }
}
