<div class="form-container">
  <div class="kaltura-layout">
    <div class="intro">
      <div class="menu-title"><img src="assets/kaltura/kaltura-logo-beta.png" /></div>
      <p>
        If you have a Kaltura account, please contact your IT department to get the following information.<br />
        <a href="https://help.spaces.wondavr.com/en/articles/2630822-kaltura-integration" target="_blank"> Check the documentation</a> or <a href="mailto:support@wondavr.com">contact us</a> to get information about Kaltura Video Platform.
      </p>
    </div>
    <p *ngIf="isNotConnected()" class="feedback-ko"> We cannot connect to your Kaltura account. Please contact your IT department</p>
    <p *ngIf="isConnected()" class="feedback-ok">Your Kaltura account is connected</p>
    <p *ngIf="serverFeedback === 'insufficient rights'" class="feedback-ko"> Server app token must have disableentitlement session privilege. Please contact your IT department</p>
    <form [formGroup]="kalturaAccountForm">
      <div class="kaltura-form-fields">
        <mat-form-field>
          <mat-label class="custom-placeholder">
            <div>Editor App Token</div>           
            <mat-icon class="feedback-icon feedback-ko" *ngIf="clientFeedback === 'ko'">error</mat-icon>
            <mat-icon class="feedback-icon feedback-ok" *ngIf="clientFeedback === 'ok'">check_circle</mat-icon>
          </mat-label>
          <textarea matInput formControlName="clientAppToken"></textarea>
        </mat-form-field>
        <mat-form-field>
        <mat-label class="custom-placeholder">
            Player App Token           
            <mat-icon class="feedback-icon feedback-ko" *ngIf="serverFeedback && serverFeedback !== 'ok'">error</mat-icon>
            <mat-icon class="feedback-icon feedback-ok" *ngIf="serverFeedback === 'ok'">check_circle</mat-icon>
          </mat-label>
          <textarea matInput formControlName="serverAppToken"></textarea>
        </mat-form-field>
        <mat-radio-group formControlName="kalturaIdMethod">
          <mat-radio-button class="mat-caption" value="email" >
            Use the email as the user ID
          </mat-radio-button>
          <mat-radio-button class="mat-caption" value="emailprefix" >
            Use the email prefix as the user ID
          </mat-radio-button>
          <mat-radio-button class="mat-caption" value="custom" >
            Use a custom ID
          </mat-radio-button>
          </mat-radio-group>
      </div>
    </form>
  </div>
  <div class="confirm-button">
    <button mat-raised-button color="primary" aria-label="CONFIRM" (click)="updateKalturaAccount()">
      CONFIRM
    </button>
  </div>
</div>