<div class="drag-drop" ngFileDrop 
  (uploadOutput)="onUploadOutput($event)"
  [ngClass]="{ 'is-drop-over': dragOver === true }"
  [options]="options">
  <div class="drag">
    <label class="upload-button">
      <img *ngIf='imgSrc; else uploadIcon' [src]="imgSrc" width="100%" position="center" style="object-fit: contain">
      <ng-template #uploadIcon>
        <div class="icon-wrapper">
          <p *ngIf="showSpinner; else icon" class="spinner">
            <mat-spinner [color]="$wonda-app-accent" [diameter]="30"></mat-spinner>
          </p>
          <ng-template #icon>
            <mat-icon id="upload-icon">add_photo_alternate</mat-icon>
          </ng-template>
        </div>
      </ng-template>
      <p *ngIf="rejectedFileErrorMsg" style="color:red;">
        {{ rejectedFileErrorMsg }}
      </p>
      <div *ngIf="!showSpinner && !showUploaded">
        <!-- <p><i>Click or Drag & drop your file here</i></p>  -->
      </div>
      <input type="file" ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [options]="options">
    </label>
  </div>
</div>
<img class="forThumbnail" #imageForThumbnail crossorigin="anonymous" />
<canvas class="forThumbnail" #canvasForThumbnail></canvas>