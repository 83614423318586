<div class="wrap learnspace-theme account-editor-container">
  <breadcrumbs class="breadcrumbs" *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></breadcrumbs>
  <h1>User Settings</h1>

  <mat-tab-group class="tabs-size" >
    <mat-tab label="Account Details" color="white" class="learnspace-theme">
        <div class="form-container mat-card">
          <form [formGroup]="accountForm" id="account-form">
            <div class="form-fields">
              <mat-form-field>
                <input matInput placeholder="First Name" formControlName="first_name">
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Organisation" formControlName="organization">
              </mat-form-field>
              <div id="radio-title">
                <span class="bold">Associated Hubs</span>
                <span class="small">Select your primary hub</span>
              </div>
              <div formGroupName="preferences" *ngIf="wondaUser">
                <mat-radio-group formControlName="defaultHub" id="hubselector">
                  <mat-radio-button *ngFor="let hub of wondaUser.Hubs" value="{{hub.id}}">{{ hub.title }} <span *ngIf="hub.id === wondaUser.preferences.defaultHub">(Primary)</span></mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </form>
          <div class="buttons">
            <button (click)="openDeleteConfirmation()">
              DELETE MY ACCOUNT
            </button>
            <button mat-raised-button color="primary" class="login-button submit-button" aria-label="CONFIRM" (click)="update()">
              CONFIRM
            </button>
          </div>
        </div>
    </mat-tab>
    <mat-tab label="Integrations">
      <get-third-party-integrations [hubs]="wondaUser.Hubs"></get-third-party-integrations>
    </mat-tab>
  </mat-tab-group>
</div>