import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Hub } from '@shared/models';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { InternalAnalyticsService } from '@shared/services/internalAnalytics.service';
@Component({
  selector: 'edit-third-party-integrations',
  templateUrl: './edit-third-party-integrations.component.html',
  styleUrls: ['./edit-third-party-integrations.component.scss']
})
export class EditThirdPartyIntegrationsComponent implements OnChanges {
  @Input() hub: Hub;
  @Input() cannotEditMessage: string;

  cannotSetSSO: boolean;
  cannotSetLTI: boolean; 
  cannotSetIntegrations: boolean;

  constructor(
    private _currentHubService: CurrentHubService,
    private _internalAnalyticsService: InternalAnalyticsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.cannotSetSSO = !this._currentHubService.plan.allowSSO;
    this.cannotSetLTI = !this._currentHubService.plan.allowLTI;
    this.cannotSetIntegrations = !this._currentHubService.plan.allowIntegrations;
  }
  resetConnections(): void {
    this._currentHubService.updateHubIntegrations(this.hub);
  }

  contactSales(): void {
    this._internalAnalyticsService.showNewMessage();
  }
}
