import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
// @ts-ignore
import { BUILD_DATE, getBuildInfoMessage, GIT_VERSION } from '@wondavrspaces/get-build-information';
import { hmrBootstrap } from 'hmr';

console.log(getBuildInfoMessage(environment.version, GIT_VERSION, BUILD_DATE));

if (environment.production) {
    enableProdMode();
}

// add intercom and pendo script
if (environment.intercom_app_id) {
    (<any>window).intercomSettings = {
        app_id: environment.intercom_app_id,
        hide_default_launcher: true
    };
    const intercomScript = document.createElement("script");
    intercomScript.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${environment.intercom_app_id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()`;
    document.head.appendChild(intercomScript);
}

if (environment.heap_id) {
    const heapScript = document.createElement("script");
    heapScript.innerHTML = `
window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
heap.load("${environment.heap_id}");
    `;
    document.head.appendChild(heapScript);
}

// add mux.js lib to support HLS with shaka player
const muxElm = document.createElement("script");
muxElm.defer = true;
muxElm.src = `${environment.assetsBucketPrefix}/js/mux.js`;
document.head.appendChild(muxElm);

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

// if (environment.hmr) {
//     if (module['hot']) {
//         hmrBootstrap(module, bootstrap);
//     }
//     else {
//         console.error('HMR is not enabled for webpack-dev-server!');
//         console.log('Are you using the --hmr flag for ng serve?');
//     }
// } else {
    bootstrap().catch(err => console.log(err));
// }
