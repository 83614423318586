import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Annotation, Course, Section, Scene } from '@shared/models';

@Injectable()
export class SupervisorService {
    private reloadCourseSubj: BehaviorSubject<{ action: string, type: string, content: any }> = new BehaviorSubject({ action: null, type: null, content: null });
    private selectionHandler: BehaviorSubject<{ type: string, content: Course | Section | Scene }> = new BehaviorSubject({ type: null, content: null });
    private selectedAnnotationId: BehaviorSubject<{ id: string, forceSelection: boolean }> = new BehaviorSubject({ id: null, forceSelection: false });
    private reloadSectionSubj: BehaviorSubject<Section> = new BehaviorSubject(null);
    private reloadSceneSubj: BehaviorSubject<Scene> = new BehaviorSubject(null);
    private reloadAnnotationListSubj: BehaviorSubject<any> = new BehaviorSubject(null);

    reloadCourseObs: Observable<{ action: string, type: string, content: any }> = this.reloadCourseSubj.asObservable();
    reloadSectionObs: Observable<Section> = this.reloadSectionSubj.asObservable();
    reloadSceneObs: Observable<Scene> = this.reloadSceneSubj.asObservable();
    updateEditor: Observable<{ type: string, content: Course | Section | Scene, selectedAnnotation?: Annotation }> = this.selectionHandler.asObservable();
    reloadAnnotationListObs: Observable<any> = this.reloadAnnotationListSubj.asObservable();
    selectedAnnotationIdObs: Observable<{ id: string, forceSelection: boolean }> = this.selectedAnnotationId.asObservable();

    constructor(private _http: HttpClient) { }

    askForCourseReload(e: { action: string, type: string, content: any }): void {
        this.reloadCourseSubj.next(e);
    }
    askForSectionUpdate(section: Section) {
        this.reloadSectionSubj.next(section);
    }
    askForSceneUdpate(scene: Scene) {
        this.reloadSceneSubj.next(scene);
    }
    askForEditorUpdate(e: { type: string, content: Course | Section | Scene, selectedAnnotation?: Annotation }): void {
        if (e.selectedAnnotation) {
            this.pushSelectedAnnotationId(e.selectedAnnotation.id, true);
        } else {
            this.pushSelectedAnnotationId(null);
        }
        this.selectionHandler.next(e);
    }
    askForAnnotationListReload() {
        this.reloadAnnotationListSubj.next(true);
    }
    pushSelectedAnnotationId(id: string, forceSelection = false) {
        this.selectedAnnotationId.next({id, forceSelection});
    }
}
