<div class="wrap learnspace-theme account-editor-container">
  <breadcrumbs class="breadcrumbs" *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></breadcrumbs>
  <div id="logo-container">
    <img *ngIf="hub?.logo" src="{{hub.logo.path}}">
    <h1>General Settings</h1>
  </div>

  <div id="tab-container">
    <mat-tab-group vertical id="tab-group">
      <mat-tab label="Branding" color="white" class="learnspace-theme">
        <ng-template matTabContent>
          <div class="branding-tab">
            <div class="tab-content">
              <form [formGroup]="hubForm" *ngIf="hub" id="branding-form">
                <p>Edit Hub Name</p>
                <mat-form-field style="width: 200px">
                  <input matInput formControlName="title">
                </mat-form-field>
                <p>Edit your Hub Logo</p>
                <div class="add-logo-button" *ngIf="hub">
                  <file-dropper [imgPath]="hub.logo?.path" (assetUploaded)="addLogoId($event)"></file-dropper>
                </div>
                <span class="subtext">Click on image to upload a new logo</span>
                <ng-container formGroupName="properties">
                    <mat-checkbox formControlName="displayWatermark" plan-limit [canDo]="!this._currentHubService.plan.forceWatermark" matTooltipPosition="left"> Display "Made with Wonda" </mat-checkbox>
                </ng-container>
              </form>
              <button class="save-button" mat-raised-button color="primary" (click)="save()">Save</button>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Users" color="white" class="learnspace-theme">
        <ng-template matTabContent>
          <div class="users-tab">
            <div id="top-bar">
              <mat-form-field class="search-bar">
                <mat-icon matPrefix (click)="setSearch()" [class.disabled]="isFetchingUsers">search</mat-icon>
                <mat-label>Search Users</mat-label>
                <input matInput type="text" [(ngModel)]="currentSearch" (change)="setSearch()" [disabled]="isFetchingUsers">
                <mat-icon *ngIf="currentSearch" matSuffix (click)="clearSearch()" [class.disabled]="isFetchingUsers">close</mat-icon>
              </mat-form-field>

              <button class="invit-button" mat-raised-button (click)="inviteNewUserToHub()">
                <mat-icon>add_circle_outline</mat-icon> INVITE NEW USERS
              </button>
            </div>

            <div class="tab-content" (scroll)="onTableScroll($event)">
              <table #table mat-table *ngIf="hubUsers" [dataSource]="hubUsers">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let user"> {{user.first_name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef> Email </th>
                  <td mat-cell *matCellDef="let user"> {{user.email}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef> Role </th>
                  <td mat-cell *matCellDef="let user">
                    <mat-select id="role-{{user.id}}" [value]="user.HubsUsers.role" (selectionChange)="updateUserRole($event, user)">
                      <mat-option value="admin">Admin</mat-option>
                      <mat-option value="user">User</mat-option>
                    </mat-select>
                  </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let user">
                    <mat-icon class="action-icon" (click)="editUser(user)">edit</mat-icon>
                    <mat-icon class="action-icon" *ngIf="user.HubsUsers.role !== 'admin'" (click)="kickUser(user)">delete</mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <div *ngIf="hubUsers && hubUsers.length === 0" class="no-result">
                No Result found
              </div>
            </div>
          </div>
          <div *ngIf="hub.plan === 'free'" class="disabled-overlay text-container">
            <p class="center-text darkblue-text"> {{ planExpiredMessage }}
              <br>
              <span class="sales-button" (click)="contactSales()">
                Please upgrade your account to enable them.
              </span>
            </p>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Integrations">
        <ng-template matTabContent>
          <edit-third-party-integrations [hub]="hub" [cannotEditMessage]="planExpiredMessage"></edit-third-party-integrations>
        </ng-template>
      </mat-tab>
      <mat-tab label="Billing" color="white" class="learnspace-theme">
        <ng-template matTabContent>
          <div class="tab-content">
            <br>
            <div class="text-container">
              <p *ngIf="hub.plan ==='free'; else paid" class="center-text darkblue-text">
                <ng-container *ngIf="planExpiredMessage">
                  {{ planExpiredMessage }}
                  <br>
                  <span class="sales-button" (click)="contactSales()">
                    Please upgrade your account to enable them.
                  </span>
                </ng-container>
              </p>
              <ng-template #paid>
                <p class="plan-text darkblue-text">
                  You are using a paying plan.
                  <br> Total Users: {{ _hubUsersTotal }}
                  <span *ngIf="hub.plan_seats">
                    <br>User seats available: {{ hub.plan_seats }}</span>
                  <br>
                  <br> Your subscription ends on {{ planEndDate | date }}
                  <br>
                  <br>
                  <span class="sales-button" (click)="contactSales()">Contact Sales to renew</span>
                </p>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>