export enum AssetType {
    i2D = '2D-Image',
    v2D = '2D-Video',
    i360 = '360-Image',
    v360 = '360-Video',
    sound = 'sound',
    o3D = 'object3D',
    r3D = 'room3D',
    npc = 'npc'
}
const Object3DAcceptedMimeTypes = ['', 'model/gltf-binary', 'model/gltf+json',
    'application/octet-stream', 'application/macbinary',
    'text/plain', // We are allowing text because the license file might be in the gltf uncompressed folder
    'image/png', 'image/jpeg', 'image/jpg',
    'application/zip', 'application/x-zip-compressed', ''
];
export const AssetData = new Map<string, { typeName: string, mediaExtensions: string[] }>([
    [AssetType.i2D, { typeName: 'Image', mediaExtensions: ['image/jpeg', 'image/png'] }],
    [AssetType.i360, { typeName: '360° Image', mediaExtensions: ['image/jpeg', 'image/png'] }],
    [AssetType.v2D, { typeName: 'Video', mediaExtensions: ['video/mp4'] }],
    [AssetType.v360, { typeName: '360° Video', mediaExtensions: ['video/mp4'] }],
    [AssetType.sound, { typeName: 'Sound', mediaExtensions: ['audio/mp3', 'audio/mpeg'] }],
    [AssetType.npc, { typeName: 'AI Character', mediaExtensions: Object3DAcceptedMimeTypes }],
    [AssetType.o3D, { typeName: '3D Object', mediaExtensions: Object3DAcceptedMimeTypes }],
    [AssetType.r3D, { typeName: '3D environment', mediaExtensions: Object3DAcceptedMimeTypes }]
]);


