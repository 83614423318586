import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '@app/main/wonda/i18n/en';
import { locale as french } from '@app/main/wonda/i18n/fr';
import { Course, Hub } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { Observable } from 'rxjs';
import { SupervisorService } from '@shared/services/supervisor.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CurrentHubService } from '@shared/services/currentHub.service';
@Component({
  selector: 'wonda',
  templateUrl: './wonda.component.html',
  styleUrls: ['./wonda.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class WondaComponent implements OnInit {

  course: Observable<Course>;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    private _router: Router,
    private _courseApiService: CoursesAPIService,
    private _supervisorService: SupervisorService,
    private _activeRouter: ActivatedRoute,
    private _authservice: AuthenticationService,
    private _hubService: CurrentHubService
  ) { }
  ngOnInit(): void {
    this._authservice.getWondaUser().then(user => {
      if (user.Hubs.length === 0) {
        this._router.navigate(['/createHub']);
      } else {
        let newHub: Hub;
        if (user.preferences && user.preferences.defaultHub) {
          newHub = user.Hubs.find((hub) => {
            return hub.id === user.preferences.defaultHub;
          });
        }
        if (newHub === undefined) {
          newHub = user.Hubs[0];
        }
        this._hubService.setCurrentHub(newHub).then(hub => {
          this._router.navigate([hub.urlTitle]);
        })
      }
    })
  }
}
