import { Component, OnInit, Input } from '@angular/core';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { Hub } from '@shared/models';
import { MatSelectChange } from '@angular/material/select';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Integrations } from '@shared/models/integrations.model';

@Component({
  selector: 'get-third-party-integrations',
  templateUrl: './get-third-party-integrations.component.html',
  styleUrls: ['./get-third-party-integrations.component.scss']
})
export class GetThirdPartyIntegrationsComponent implements OnInit {
  @Input() hubs: Hub[];
  hubIntegrations: Integrations;  

  constructor(
    private _courseApiService: CoursesAPIService,
    private _overlayContainer: OverlayContainer,
  ) { 
    this._overlayContainer.getContainerElement().classList.remove('editor-theme');
    this._overlayContainer.getContainerElement().classList.add('learnspace-theme');
  }

  ngOnInit() {
    this._courseApiService.getHubIntegrationsInfo(this.hubs[0].id).subscribe(
      integrations => {
        this.hubIntegrations = integrations;
      }
    )
  }

  getHubInfo(event: MatSelectChange) {
    this._courseApiService.getHubIntegrationsInfo(event.value).subscribe(
      integrations => {
        this.hubIntegrations = integrations;
      }
    )
  }

  goToHelp(url: string): void {
    window.open(url);
  }
}
