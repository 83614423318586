import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@shared/models/user.model';
import { Router } from '@angular/router';
import { AccountDeleteConfirmationComponent } from '@app/account-editor/account-delete-confirmation-dialog/account-delete-confirmation-dialog.component';
import { Subscription } from 'rxjs/Subscription';
import { BreadCrumb } from '@shared/components/breadcrumbs/breadcrumb.model';

@Component({
  selector: 'account-editor',
  templateUrl: './account-editor.component.html',
  styleUrls: ['./account-editor.component.scss'],
})
export class AccountEditorComponent implements OnInit, OnDestroy {
  accountForm: FormGroup;
  wondaUser: User;
  fireBaseUser: any;
  configURL: string;
  userStateSub: Subscription;
  public breadcrumbs: BreadCrumb[];
  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */

  constructor(
    private _authService: AuthenticationService,
    private _courseApiService: CoursesAPIService,
    private _dialog: MatDialog,
    private _router: Router
  ) {
    this.accountForm = new FormGroup({
      first_name: new FormControl(''),
      organization: new FormControl(''),
      preferences: new FormGroup({
        defaultHub: new FormControl('')
      })
    });
  }

  ngOnInit(): void {

    this.wondaUser = this._authService.wondaUser;
    this.userStateSub = this._authService.userState.subscribe(user => {
      this.wondaUser = user;
      this.resetForm();
    });
    this.resetForm();
    // Init Breadcrumbs
    this.breadcrumbs = [
      new BreadCrumb(null, 'home', () => {
        this._router.navigate(["/"]);
      }),
      new BreadCrumb('User Settings', null, null)
    ]
  }

  ngOnDestroy(): void {
    this.userStateSub.unsubscribe();
  }
  resetForm(): void {
    if (this.wondaUser) {
      this.accountForm.reset(this.wondaUser);
    }
  }
  update(): void {
    this._courseApiService.updateMyUser(this.accountForm.value).subscribe(user => {
      this.wondaUser = user;
      this._router.navigate(['/']);
    });
  }

  openDeleteConfirmation(): void {
    this._dialog.open(AccountDeleteConfirmationComponent, {
      width: '50vw',
      height: '25vh',
      panelClass: 'learnspace-theme',
      data: { title: 'Delete my account', type: 'Account' }
    });
  }
}
