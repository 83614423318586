import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { Asset, AssetType, AssetProviderType } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { HttpClient } from '@angular/common/http';
import { AssetsListComponent } from '@shared/components/file-manager/assets-list/assets-list.component';
@Component({
  selector: 'ready-player-me-link',
  templateUrl: './ready-player-me-link.component.html',
  styleUrls: ['./ready-player-me-link.component.scss']
})
export class ReadyPlayerMeLinkComponent implements OnInit {
  @Input() type: AssetType;
  @Output() selected = new EventEmitter<Asset>();

  @ViewChild(AssetsListComponent, { static: false }) assetsList: AssetsListComponent;
  @ViewChild('textSearch') textSearch: ElementRef;

  pathError: Error;

  private _loading = false;

  constructor(
    private _courseService: CoursesAPIService,
    private _http: HttpClient
  ) { }
  ngOnInit(): void {}

  async validatePathAndCreateMedia(path: string): Promise<void> {
    this.pathError = undefined;
    this._loading = true;
    try {
      const isRPMValidURL = path.startsWith('https://models.readyplayer.me/') && path.endsWith('.glb');
      if (!isRPMValidURL) {
        throw new Error('Invalid Ready Player Me URL');
      }

      try {
        await this._http.get(path, { responseType: 'text' }).toPromise();
      } catch (error) {
        console.error(error);
        throw new Error('Cannot get specified URL');
      }

      const rpmAvatarPath = path.split('https://models.readyplayer.me/')[1];
      const rpmID = rpmAvatarPath.split('.glb')[0];

      const res = await fetch(`https://models.readyplayer.me/${rpmID}.json`);
      const metadata = await res.json();
      if (metadata.bodyType !== 'fullbody') {
        throw new Error('Avatar should be a full body');
      }
      const gender = metadata ? metadata.outfitGender : undefined;

      const media = new Asset(
        this.type,
        'no desc',
        undefined,
        'rpm_' + rpmID.substring(0, 5),
        undefined,
        path,
        null,
        path.split('.glb')[0] + '.png',
        {
          gender: gender
        },
        false,
        AssetProviderType.readyPlayerMe,
      );

      const asset = await this._courseService.createMedia(media).toPromise();
      this.assetsList.loadAssets();
      this.textSearch.nativeElement.value = '';
      this._loading = false;
    } catch (error) {
      this._loading = false;
      this.pathError = error;
    }
  }

  canSave(): boolean {
    return !this._loading;
  }

  onCurrentSelect(event: any): void {
    this.selected.emit(event); //just propagate to parent
  }

  goToHelp(): void {
    window.open('https://help.spaces.wondavr.com/en/articles/8134882-add-a-npc-annotation');
  }
}
