<div class="feedback-content" *ngIf="invitationRequestFeedback else mailInput">
  <span class="feedback" [ngClass]="{ 'error-msg': this.invitationRequestFeedback.startsWith('Error') }">{{ this.invitationRequestFeedback }}</span>
  <div class="feedback-buttons">
    <button mat-button color="primary" (click)="resetForm()" style="margin-right: 6px">INVITE MORE PARTICIPANTS</button>
    <button mat-raised-button color="primary" (click)="done.emit()">DONE</button>
  </div>
</div>
<ng-template #mailInput>
  <div [formGroup]="emailForm" class="items-wrapper">
    <h3>{{ instructionMessage }}
      <mat-icon (click)="openHelp()">help</mat-icon>
    </h3>
    <div *ngIf="!!invitationLink" class="invitation-link">
      <div class="link-container">
        <div class="section-title" matTooltip="Share this unique URL on your internal website or by email to let people join your Hub">
          Direct link to join your Hub</div>
        <input #url class="invitation-url" type="text" [value]="invitationLink" readonly (click)="$event.target.select()">
        <mat-icon (click)="copy()">content_copy</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="email-invitation">
      <div class="email-list">
        <div class="section-title" matTooltip="Use this to invite individual users to specific Spaces and/or admin permission"> Invite by email</div>
        <tag-input plan-limit [canDo]="canDo" matInput formControlName="email" [theme]="'minimal'" [placeholder]="'Email'" [secondaryPlaceholder]="'Email'"
          [editable]="true" [separatorKeyCodes]="[9, 10, 13, 32, 59, 188]" [validators]="validateEmail" [errorMessages]="asyncErrorMessages"
          [addOnBlur]="true" [addOnPaste]="true" [pasteSplitPattern]="splitPattern">
        </tag-input>
        <div *ngIf="manageRole" class="role-selector">
          <span> as</span>
          <mat-select formControlName="role">
            <mat-option style="font-size: 12px;" value="admin">Admin</mat-option>
            <mat-option style="font-size: 12px;" value="user">User</mat-option>
          </mat-select>
        </div>
      </div>
      <div *ngIf="showDetailList && lsFromHub" class="ls-list-block">
        <div class="ls-list-title">+ Grant access to:</div>
        <div class="ls-list">
          <div *ngFor="let ls of lsFromHub">
            <mat-checkbox (change)="addToList(ls.id, $event)"> {{ ls.title }} </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="bottom-buttons">
        <button mat-raised-button color="primary" (click)="submitEmail()" plan-limit [canDo]="canDo">SEND INVITATIONS</button>
      </div>
    </div>
  </div>
</ng-template>