import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@shared/services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
    email: string;
    passwordError: string = null;
    emailSent: boolean;
    passwordForm: FormGroup;

    constructor(
        public _dialogRef: MatDialogRef<PasswordResetComponent>,
        private _authService: AuthenticationService,
        private _router: Router,

        @Inject(MAT_DIALOG_DATA) public _data: any //TODO declare model
    ) {
        this.passwordForm = new FormGroup({
            email: new FormControl('', { validators: Validators.required })
          });
        
    }

    ngOnInit(): void {

        this.email = this._data.email;
        this.emailSent = false;
        this.passwordForm.reset(this._data);

    }

    reset(): void{
        this._authService
            .resetCurrentUserPassword(this.passwordForm.value.email)
            .then(() => {
                this.passwordError = null;
                this.emailSent = true;
            })
            .catch((err: Error) => {
                this.passwordError = err.message;
                console.error(err);
            });         
    }

    toLogin(): void{
        this._dialogRef.close();
    }
}




