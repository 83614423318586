<h2>RECOVER YOUR PASSWORD</h2>
<form [formGroup]="passwordForm" >
    <mat-form-field>
        <input matInput placeholder="Email" formControlName="email">
    </mat-form-field>
</form>
<div *ngIf="passwordError" style="color:black; text-align: center">{{ passwordError }}</div>

<div *ngIf="emailSent" style="color:black; text-align: center">Check your emails to reset your password</div>
<button mat-raised-button color="primary" (click)="reset()">SEND RESET LINK</button>
<div (click)="toLogin()" style="cursor: pointer">Back to Login</div>