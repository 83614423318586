import { Asset } from '@shared/models/asset.model';
import { Annotation } from '@shared/models/annotation.model';
import { SceneType } from '@shared/models/sceneType.model';

export class Scene {
  constructor(
    public title: string,
    public description: string,
    public type: SceneType,
    public id?: string,
    public status?: string,
    public priority?: number,
    public properties?: {
      volume?: number;
      autoplay?: boolean;
      displayVideoControls?: boolean;
      loopVideo?: boolean;
      lookat?: number;
      custom_lookat?: boolean;
      duration?: number;
      auto_transition?: boolean;
      transition_target?: string;
    },
    public section_id?: string,
    public media?: Asset,
    public media_id?: string,
    public sound_id?: string,
    public sound?: Asset,
    public annotations?: Annotation[],
    public annotationCount?: number
  ) {
    // Set default properties for video resources
    {
      this.properties = {
        volume: 100,
        autoplay: true,
        displayVideoControls: true,
        loopVideo: false,
        duration: 30,
        auto_transition: false,
        transition_target: 'next',
        custom_lookat: false
      };
    }
  }
}

