import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesAPIService } from '@shared/services/coursesApi.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  status: number;
  private sub: any;
  error: string;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _courseApiService: CoursesAPIService,
  ) { }

  ngOnInit(): void {
    this.sub = this._route.queryParams.subscribe(params => {
      this.status = +params['status']; // (+) converts string 'id' to a number
      this.error = params['error'];
      if (this.status && !this.error) {
        this.error = this.errorFromStatus(this.status);
      }
      // In a real app: dispatch action to load the details here.
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  private errorFromStatus(status: number | string): string { //TODO check status value
    switch (status) {
      case 402:
        return `This Experience is currently not available.
Please contact your administrator to re-activate it.`
      case 403:
        return 'Unfortunately, it seems that you do not have access to this content';
      case 404:
        return 'Content not found';
      default:
        return status as string;
    }
  }

  joinImlab(): void {
    this._courseApiService.associateSelfToDefaultHub().subscribe(hub => {
      this._router.navigate([hub.urlTitle])
    })
  }
}
