import { Component, OnDestroy, OnInit, ViewEncapsulation, Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '@shared/services/authentication.service';
import { InternalAnalyticsService } from '@shared/services/internalAnalytics.service';
import { KalturaService } from '@shared/services/kaltura.service';
import { Router } from '@angular/router';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Hub } from '@shared/models/hub.model';
import { OverlayContainer } from '@angular/cdk/overlay';
// import { HubEditDialogComponent } from '@app/main/hub/hub-edit/hub-edit-dialog.component';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserMenuComponent implements OnInit {
  hubObs: Observable<Hub>;
  user: any | null;
  undefined_url = 'assets/wonda/portrait.png';
  constructor(
    private _authService: AuthenticationService,
    private _internalAnalyticsService: InternalAnalyticsService,
    private _kalturaService: KalturaService,
    private _router: Router,
    private _hubService: CurrentHubService,
    private _dialog: MatDialog,
    private _overlayContainer: OverlayContainer,
    private _renderer: Renderer2,
    private _elementref: ElementRef
  ) {
    this.hubObs = this._hubService.hubObservable;
    this._overlayContainer.getContainerElement().classList.add('editor-theme');
    this._overlayContainer.getContainerElement().classList.remove('learnspace-theme');
    this._renderer.removeClass(this._elementref.nativeElement, 'learnspace-theme');
  }

  ngOnInit(): void {
    this._authService.userState.subscribe(user => {
      if (user) {
        this.user = user;
        this.user.name = user.first_name;

        const firebaseUser = this._authService.getCurrentUser();
        // Get photo from providerData
        if(firebaseUser.providerData && firebaseUser.providerData[0] && firebaseUser.providerData[0].photoURL){
          this.user.photo = firebaseUser.providerData[0].photoURL;
        }
        // Get photo from Firebase Data
        else if(firebaseUser.photoURL !== null){
          this.user.photo = firebaseUser.photoURL
        }
        else{
          this.user.photo = this.undefined_url;
        }
        this.user.Hubs = user.Hubs;
        this._internalAnalyticsService.initIntercomWithUser(user);
        this._internalAnalyticsService.identifyHeapUser(user);
      }
      else {
        this.user = null;
      }
    });
  }

  signOut(): void {
    this._authService.logout();
  }
  async switchHub(hub: Hub): Promise<void> {
    await this._hubService.setCurrentHub(hub);
    this._router.navigate([hub.urlTitle]);
  }
  isAdminOfHub(): boolean {
    return this._hubService.isAdminOfCurrentHub(this._authService.wondaUser);
  }
  openHubSettings(): void {
    const urlTitle = this._hubService.getHubURLTitle();
    this._router.navigate(['hubedit/' + urlTitle]);
  }
  openAnalytics(): void {
    const urlTitle = this._hubService.getHubURLTitle();
    this._router.navigate(['analytics/' + urlTitle]);
  }
  getDaysLeft(): number {
    return this._hubService.planTimeLeft();
  }
  goToRoot(): void {
    let newhub;
    if (this._hubService.hub && this._hubService.hub !== null){
      newhub = this._hubService.hub;
    }
    this._router.navigate([(newhub) ? newhub.urlTitle : '/']); 
  }
  contactSales(): void {
    this._internalAnalyticsService.showNewMessage();
  }
  openPlanDoc(): void {
    window.open("https://help.spaces.wondavr.com/en/articles/6212470-which-plan-is-the-right-one-for-you");
  }
  openHelp(): void{
    window.open('http://help.spaces.wondavr.com');
  }
}
