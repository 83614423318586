import { Component, OnInit } from '@angular/core';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { Course, Hub } from '@shared/models';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './metahome.component.html',
  styleUrls: ['./metahome.component.scss']
})
export class MetahomeComponent implements OnInit {
  hubs: Hub[];
  courses: Course[];

  constructor(
    private _courseApiService: CoursesAPIService,
    private _authService: AuthenticationService,
    private _hubService: CurrentHubService,
    private _router: Router

  ) { }

  ngOnInit(): void {
    this._courseApiService.getFeaturedCourses().subscribe(response => {
      this.courses = response.courses;
    });

    this._authService.getWondaUser().then(user => {
      if (user) {
        this.hubs = user.Hubs;
      }
      else {
        this.hubs = null;
      }
    });

  }
  isAdminOfHub(hub_id: string): boolean {
    return this._hubService.isAdminOfHub(this._authService.wondaUser, hub_id);
  }
  editHub(hub: Hub): void {
    this._router.navigate(['hubedit/', hub.urlTitle]);
  }
  switchHub(hub: Hub): void {
    this._hubService.setCurrentHub(hub);
    this._router.navigate([hub.urlTitle]);
  }
}
