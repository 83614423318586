import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { tap, retryWhen, map, mergeMap  } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private _authService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.coursesApiUrl)) {
      const requestWithCookies = req.clone({ withCredentials: true });
      return next.handle(requestWithCookies)
        .pipe(
          retryWhen(this.genericRetryStrategy()),
          tap(() => { },
            err => {
              if (err.status === 401) {
                this._router.navigate(['/login']);
              }
              else if (err.status === 403){
                this._router.navigate(['/error'], { queryParams: {status: err.status} });
              }
            })
        );
    } else {
      return next.handle(req);
    }
  }

  private genericRetryStrategy = ({
    maxRetryAttempts = 2,
  }: {
      maxRetryAttempts?: number,
    } = {}) => (attempts: Observable<any>) => {
      return attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          if (error.status === 401 && retryAttempt < maxRetryAttempts) {
            return this._authService.getOrRefreshToken().pipe(
              map(() => retryAttempt)
            );
          }
          throw (error);
        }),
      );
    }
}
