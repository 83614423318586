<div class="learnspace-theme dialogWrapper">
  <div class="close" (click)="this.dialogRef.close();">
    <mat-icon>clear</mat-icon>
  </div>
  <div class="participant-list">
    <h3 style="margin: 0px;">Participants</h3>
    <div>
      <div *ngFor="let participant of learnspace.Participants">
        <span>- {{participant.first_name}} {{ participant.last_name}}</span>
        <span *ngIf="isOwner(participant)">(Owner)</span>
        <span mat-icon-button *ngIf="canKick() && !isOwner(participant)" class="kick-btn" (click)="kickUser(participant.id)">
          <mat-icon >close</mat-icon>
        </span>
        
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <send-invitation [userId]="data.user_id" [targetId]="learnspace.id" [type]="invitationType" [canDo]="canInvite()" (done)="this.dialogRef.close();"></send-invitation>
</div>