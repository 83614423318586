<div class="form-container">
  <div class="layout">
    <div class="intro">
      <p>
        1. Access the
        <a [href]="metadataLink" target="_blank">Service Provider metadata here</a> to setup your Identity Provider
        <br /> 2. Fill in the Identity Provider information below:
    </div>
    <form [formGroup]="ssoIdpForm">
      <div class="sso-form-fields">
        <mat-form-field width="300px">
          <input matInput placeholder="Login url" formControlName="loginUrl">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Logout url" formControlName="logoutUrl">
        </mat-form-field>
        <mat-form-field width="400px" class="cert-form">
          <textarea matInput placeholder="Certificate" formControlName="certificate"></textarea>
        </mat-form-field>
        <p>3. List the domain available to login via sso</p>
        <tag-input matInput formControlName="allowedDomains" [theme]="'minimal'" [placeholder]="'Domain'" [secondaryPlaceholder]="'Domain'"
          [editable]="true" [separatorKeyCodes]="[13, 32, 188]" [addOnBlur]="true" [addOnPaste]="true">
        </tag-input>
      </div>
    </form>
    <div class="confirm-button">
      <button mat-raised-button color="primary" aria-label="CONFIRM" (click)="updateSSO()">
        CONFIRM
      </button>
      <div *ngIf="warning === 'NO_DOMAIN'">
          Warning! the domain list is empty
        </div>
      <div *ngIf="feedback === 'OK'" class="ok-feedback">Modification saved</div>
      <div *ngIf="feedback === 'KO'">
        Oops something went wrong.
        <br> Please try again or
        <span (click)="contactUs()" class="contact-us">contact us</span>
      </div>
    </div>
  </div>
</div>