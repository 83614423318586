import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset, AssetType, AssetProviderType } from '@shared/models';
import { IBMService } from '@shared/services/ibm.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { request } from 'http';
import { CurrentHubService } from '@shared/services/currentHub.service';
@Component({
  selector: 'ibm-selector',
  templateUrl: './ibm-selector.component.html',
  styleUrls: ['./ibm-selector.component.scss', '../file-manager.component.scss']
})
export class IBMSelectorComponent implements OnInit {
  @Input() type: AssetType;
  @Output() selected = new EventEmitter<Asset>();
  currentVideo: any;
  isLoading = false;
  streamingType = 'live'; // live or vod
  constructor(
    private _ibmService: IBMService,
    private _courseService: CoursesAPIService,
    private _currentHub: CurrentHubService
  ) { }
  ngOnInit(): void {}
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    this.IBMSearch(pastedText);
  }
  IBMSearch(contentId: string): void {
    const isLive = this.streamingType === 'live';
    this.isLoading = true;
    let getInfo; 
    
    if (isLive) {
      getInfo = this._ibmService.findIBMChannel(this._currentHub.hub.id, contentId, this.type);
    } else {
      getInfo = this._ibmService.findIBMVideo(this._currentHub.hub.id, contentId, this.type);
    }

    getInfo.subscribe(ibmVideo => {
      this.currentVideo = ibmVideo;
      this.isLoading = false;
    }, error => {
      console.error(error);
      this.isLoading = false;
      this.currentVideo = error;
    });
  }
  selectVideo(asset: Asset): void {
    this.isLoading = true;
    this._courseService.createMedia(asset).subscribe(a => {
      this.isLoading = false;
      this.selected.emit(a);
    });
  }
}
