import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { Hub } from '@shared/models/hub.model';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HubResolver implements Resolve<Hub> {
  constructor(private _courseService: CoursesAPIService, private _currentHubService: CurrentHubService) { }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Hub> {
    if (this._currentHubService.hub) {
      return this._currentHubService.hub;
    }
    const urlTitle = route.params.urlTitle;
    const hub = await this._courseService.getHub(urlTitle).toPromise();
    await this._currentHubService.setCurrentHub(hub);
    return hub;
  }
}
