import { Component, OnInit, Input } from '@angular/core';
import { Course } from '@shared/models';
import { environment } from '@env/environment';

@Component({
  selector: 'experience-card',
  templateUrl: './experience-card.component.html',
  styleUrls: ['./experience-card.component.scss']
})
export class ExperienceCardComponent implements OnInit {

  @Input() course: Course;
  constructor(
  ) { }

  ngOnInit(): void {
   
  }
  launchCourse(): void{
    const link = environment.coursesApiUrl + '/api/courses/' + this.course.id + '/room?poster=false';
    window.open(link);
  }
}
