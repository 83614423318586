import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '@shared/services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IBMService } from '@shared/services/ibm.service';
import { Hub } from '@shared/models';

@Component({
  selector: 'ibm-account-form',
  templateUrl: './ibm-account-form.component.html',
  styleUrls: ['./ibm-account-form.component.scss'],
})
export class IBMAccountFormComponent implements OnChanges {
  @Input() credentials: any;
  @Input() hubId: string;
  @Output() connectionChange = new EventEmitter<void>();

  ibmAccountForm: FormGroup;
  accountMsg: string;
  errorMsg: string;
  constructor(
    private _courseApiService: CoursesAPIService,
    private _ibmService: IBMService
  ) {
    this.ibmAccountForm = new FormGroup({
      clientId: new FormControl('', { validators: Validators.required }),
      secret: new FormControl('', { validators: Validators.required })
    });
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.hubId) {
      this._courseApiService.getHubIntegrations(this.hubId).subscribe(creds => {
        this.ibmAccountForm.reset(creds.ibm);
      })
      this.checkConnection().subscribe();
    }
  }

  updateIBMAccount(): void {
   this.credentials = this.ibmAccountForm.value;
    this._courseApiService
      .patchHubIntegrations(this.hubId, { op: 'set', values: { ibm: this.credentials }})
      .pipe(
        switchMap(() => {
          return this.checkConnection();
        })
      )
      .subscribe(() => {
        this.connectionChange.emit();
      });
  }

  checkConnection(): Observable<any> {
    return this._ibmService.checkAccount(this.hubId).pipe(
      tap(
        res => {
          const id = res.email ? res.email : res.id;
          this.accountMsg = `You are linked to ${id} IBM account `;
          this.errorMsg = null;
        },
        err => {
          this.errorMsg = 'You are not linked to a IBM account';
          this.accountMsg = null;
          console.error(err);
        }
      )
    );
  }
}
