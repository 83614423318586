import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { InvitationComponent } from '@app/main/invitation/invitation.component';
import { LoginModule } from '@app/main/login/login.module';

@NgModule({
    declarations: [
        InvitationComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        LoginModule
    ],
    entryComponents: [InvitationComponent],
    exports: [InvitationComponent]
})
export class InvitationModule { }
