<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<ng-container *ngIf="true">
    <div class="navbar">
      <user-menu></user-menu>
    </div>
    <div class="content-container editor-theme mat-typography">
      <content></content>
    </div>
</ng-container>