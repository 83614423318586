import {
  KalturaClient,
  KalturaMediaEntryFilter,
  KalturaFilterPager,
  KalturaMediaType,
  UploadTokenUploadAction,
  KalturaUploadToken,
  UploadTokenAddAction,
  SessionGetAction,
  SessionStartWidgetSessionAction,
  MediaAddAction,
  KalturaMediaEntry,
  KalturaUploadedFileTokenResource,
  MediaAddContentAction,
  MediaUpdateAction,
  KalturaEntryStatus,
  KalturaNullableBoolean,
  KalturaLiveStreamEntry,
  KalturaLiveStreamConfiguration,
  KalturaESearchEntryItem,
  KalturaESearchItemType,
  KalturaESearchEntryFieldName,
  KalturaESearchEntryOperator,
  KalturaESearchEntryParams,
  ESearchSearchEntryAction,
  KalturaESearchOperatorType,
  KalturaESearchOrderBy,
  KalturaESearchEntryOrderByItem,
  KalturaESearchSortOrder,
  KalturaESearchEntryOrderByFieldName
} from 'kaltura-ngx-client';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, zip } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { AssetType, Asset, AssetProviderType, Integrations, User } from '@shared/models';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { UploadFile } from 'ngx-uploader';
import { UploadService } from '@shared/services/upload.service';

@Injectable()
export class KalturaService {
  static CLIENT_TAG = 'WondaVR_Spaces';
  static BASE_URL = 'https://www.kaltura.com';
  static UPLOAD_URL = 'https://upload.kaltura.com';
  static userId: string;

  constructor(
    private _kaltura: KalturaClient,
    private _http: HttpClient,
    private _courseApiService: CoursesAPIService,
    private _uploadService: UploadService
  ) {
    this.setDefaultOptions();
  }

  private static convertWondaToKalturaType(type: AssetType, streamType?: string): KalturaMediaType {
    switch (type) {
      case AssetType.i2D:
      case AssetType.i360:
        return KalturaMediaType.image;
      case AssetType.v2D:
      case AssetType.v360: {
        if (streamType === 'live') {
          return KalturaMediaType.liveStreamFlash
        }
        return KalturaMediaType.video;
      }

      case AssetType.sound:
        return KalturaMediaType.audio;
      default:
        throw new Error('Unknown type');
    }
  }

  private static getKalturaTypeFilter(type: AssetType, streamType?: string): KalturaMediaEntryFilter {
    switch (type) {
      case AssetType.i2D:
        return new KalturaMediaEntryFilter({
          mediaTypeEqual: KalturaMediaType.image
        });
      case AssetType.i360:
        return new KalturaMediaEntryFilter({
          mediaTypeEqual: KalturaMediaType.image,
          tagsLike: '360'
        });
      case AssetType.sound:
        return new KalturaMediaEntryFilter({
          mediaTypeEqual: KalturaMediaType.audio
        });
      case AssetType.v2D: {
        if (streamType === 'live') {
          return new KalturaMediaEntryFilter({
            mediaTypeEqual: KalturaMediaType.liveStreamFlash
          })
        }
        return new KalturaMediaEntryFilter({
          mediaTypeEqual: KalturaMediaType.video
        });
      }
      case AssetType.v360: {
        if (streamType === 'live') {
          return new KalturaMediaEntryFilter({
            mediaTypeEqual: KalturaMediaType.liveStreamFlash,
            tagsLike: '360'
          })
        }
        return new KalturaMediaEntryFilter({
          mediaTypeEqual: KalturaMediaType.video,
          tagsLike: '360'
        });
      }
      default:
        throw new Error(`wrong filter type (${type}) for Kaltura list`);
    }
  }

  private static getKalturaUserId(method: string, user: User): string {
    const email = user.email;
    let result;
    switch (method) {
      case 'emailprefix': {
        result = email.split('@')[0];
        break;
      }
      case 'custom': {
        if (user.preferences && user.preferences.kalturaCustomId) {
          result = user.preferences.kalturaCustomId;
        } else if (user.preferences && user.preferences.ssoUserId) {
          result = user.preferences.ssoUserId;
        } else {
          console.warn('No custom id found for Kaltura session, using user email instead')
          result = email;
        }
        break;
      }
      default: {
        result = email;
      }
    }
    return result;
  }

  private searchMedia(type?: AssetType, name?: string, streamType?: string, customFilter?: KalturaMediaEntryFilter): Observable<any> {

    const mediaTypeFilter = new KalturaESearchEntryItem({
      fieldName: KalturaESearchEntryFieldName.mediaType,
      searchTerm: KalturaService.convertWondaToKalturaType(type, streamType).toString(),
      itemType: KalturaESearchItemType.exactMatch
    });

    const noYoutubeVideoFilter = new KalturaESearchEntryOperator({
      searchItems: [new KalturaESearchEntryItem({
        fieldName: KalturaESearchEntryFieldName.sourceType,
        searchTerm: "Youtube",
        itemType: KalturaESearchItemType.exactMatch
      })],
      operator: KalturaESearchOperatorType.notOp
    });

    const andOperator = new KalturaESearchEntryOperator({
      searchItems: [mediaTypeFilter, noYoutubeVideoFilter],
      operator: KalturaESearchOperatorType.andOp
    });

    if (!!name) {
      const nameFilter = new KalturaESearchEntryItem({
        fieldName: KalturaESearchEntryFieldName._name,
        searchTerm: name,
        itemType: KalturaESearchItemType.partial
      });
      const idFilter = new KalturaESearchEntryItem({
        fieldName: KalturaESearchEntryFieldName.id,
        searchTerm: name,
        itemType: KalturaESearchItemType.exactMatch
      });
      const nameOrIdFilter = new KalturaESearchEntryOperator({
        searchItems: [nameFilter, idFilter],
        operator: KalturaESearchOperatorType.orOp
      })
      andOperator.searchItems.push(nameOrIdFilter);
    }

    const withTag360Filter = new KalturaESearchEntryItem({
      fieldName: KalturaESearchEntryFieldName.tags,
      searchTerm: '360',
      itemType: KalturaESearchItemType.exists
    });

    if (type === AssetType.i360 || type === AssetType.v360) {
      andOperator.searchItems.push(withTag360Filter);
    } else {
      const noTag360Filter = new KalturaESearchEntryOperator({
        searchItems: [withTag360Filter],
        operator: KalturaESearchOperatorType.notOp
      })
      andOperator.searchItems.push(noTag360Filter);
    }

    return this._kaltura.request(new ESearchSearchEntryAction({
      searchParams: new KalturaESearchEntryParams({
        orderBy: new KalturaESearchOrderBy({
          orderItems: [
            new KalturaESearchEntryOrderByItem({
              sortOrder: KalturaESearchSortOrder.orderByDesc,
              sortField: KalturaESearchEntryOrderByFieldName.createdAt
            }),
          ]
        }),
        searchOperator: andOperator,
      }),
      pager: new KalturaFilterPager({
        pageSize: 50
      })
    }));
  }

  // KalturaMediaEntry | KalturaLiveStreamEntry
  private static parseKalturaMedia(kMedia: any | KalturaLiveStreamEntry, type: AssetType, userId: string): Asset {
    let properties: any = {};
    if (type === AssetType.sound) {
      properties.duration = kMedia.msDuration / 1000;
    } else {
      properties.originalHeight = '' + kMedia.height;
      properties.originalWidth = '' + kMedia.width;
      if (type === AssetType.v2D || type === AssetType.v360) {
        properties.duration = kMedia.msDuration / 1000;
        if (kMedia.mediaType === KalturaMediaType.liveStreamFlash) {
          properties.isLive = true;
          const liveHls = kMedia.liveStreamConfigurations.find((stream: KalturaLiveStreamConfiguration) => stream.protocol === 'hls');
          properties.variants = {
            hls: {
              path: liveHls.url,
            }
          };
        } else {
          properties.isVOD = true;
          properties.variants = {
            dash: {
              path: `https://cdnapisec.kaltura.com/p/${kMedia.partnerId}/sp/${kMedia.partnerId
                }00/playManifest/entryId/${kMedia.id
                }/format/mpegdash/protocol/https/dashmanifest.mpd`
            },
            lr: {
              path: kMedia.downloadUrl
            }
          };
        }
      }
    }
    properties.kaltura = {
      mediaId: kMedia.id
    };
    return new Asset(
      type,
      'Kaltura media',
      undefined,
      kMedia.name,
      userId,
      kMedia.downloadUrl,
      null,
      kMedia.thumbnailUrl,
      properties,
      false,
      AssetProviderType.kaltura
    );
  }


  private setDefaultOptions() {
    this._kaltura.setOptions({
      clientTag: KalturaService.CLIENT_TAG,
      endpointUrl: KalturaService.BASE_URL
    });
  }

  private setUploadOptions() {
    this._kaltura.setOptions({
      clientTag: KalturaService.CLIENT_TAG, // client tag parameter is mandatory
      endpointUrl: KalturaService.UPLOAD_URL, // request upload token on this specific server to be sure to point to the closest server
      chunkFileDisabled: false,
      chunkFileSize: 10e6
    })
  }
  private getKalturaSessionHash(
    hubId: string,
    ks: string,
    userId: string
  ): Observable<any> {
    return this._http.post(
      `${environment.coursesApiUrl}/api/hubs/${hubId}/kaltura/session`,
      { ks, userId }
    );
  }

  private createUploadToken(file: File): Observable<KalturaUploadToken> {
    const uploadToken = new KalturaUploadToken(
      {
        fileName: file.name,
        fileSize: file.size,
        autoFinalize: KalturaNullableBoolean.trueValue
      });
    return this._kaltura
      .request(new UploadTokenAddAction({ uploadToken }));
  }

  startKalturaSession(hubId: string, user: User): Observable<any> {
    let integrationsInfos: Integrations;
    return this.endKalturaSession().pipe(
      switchMap(() => this._courseApiService.getHubIntegrationsInfo(hubId)),
      switchMap(infos => {
        integrationsInfos = infos;
        if (infos.kaltura) {
          const partnerId = infos.kaltura.partnerId;
          return this._kaltura.request(
            new SessionStartWidgetSessionAction({
              widgetId: '_' + partnerId,
              expiry: 86400
            })
          );
        } else {
          throw new Error('No kaltura credentials');
        }
      }),
      switchMap(ksResponse => {
        this._kaltura.setDefaultRequestOptions({ ks: ksResponse.ks });
        const kalturaId = KalturaService.getKalturaUserId(integrationsInfos.kaltura.idMethod, user);
        KalturaService.userId = kalturaId;
        return this.getKalturaSessionHash(hubId, ksResponse.ks, kalturaId);
      }),
      tap(sessionResp => {
        this._kaltura.setDefaultRequestOptions({
          ks: sessionResp.ks
        });
      })
    );
  }

  endKalturaSession(): Observable<any> {
    /** End session is only available for admin users */
    // return this._kaltura.request(new SessionEndAction({})).pipe(
    //   catchError(err => {
    //     console.log('Error at logout', err);
    //     return of('no logout');
    //   })
    // );
    this._kaltura.setDefaultRequestOptions({});
    return of('not a real logout');
  }

  /** Get session is only available for admin users */
  getSessionInfo(): Observable<any> {
    return this._kaltura.request(new SessionGetAction({}));
  }

  getFilteredAndFormattedMediaList(
    type?: AssetType,
    userId?: string,
    name?: string,
    streamType?: string
  ): Observable<Asset[]> {
    return this.searchMedia(type, name, streamType).pipe(
      map(response => {
        const list = response.objects;
        return list.map((o: { object: any; }) => {
          const m = o.object;
          if (m.status !== KalturaEntryStatus.ready) {
            // create fake asset with no id
            const asset = new Asset(type, 'not ready', null, m.name);
            asset.provider = AssetProviderType.kaltura;
            return asset;
          }
          return KalturaService.parseKalturaMedia(m, type, userId);
        });
      })
    );
  }

  startUpload(file: UploadFile, type: AssetType): Observable<any> {
    const fileToUpload = file.nativeFile;
    this.setUploadOptions();
    return this.createUploadToken(file.nativeFile)
      .pipe(
        switchMap(token => {
          const uploadTokenId = token.id;
          const fileData = fileToUpload;
          const resume = false;
          const finalChunk = true;
          const resumeAt = -1;
          const uploadRequest = new UploadTokenUploadAction({
            uploadTokenId,
            fileData,
            resume,
            finalChunk,
            resumeAt
          })

          uploadRequest.setProgress(event => {
            const progress = (event / fileToUpload.size);
            this._uploadService.progress.next({
              name: fileToUpload.name,
              progress
            })
          })
          return this._kaltura.request(uploadRequest);
        }),
        tap(() => this.setDefaultOptions()),
        switchMap(uploadTokenRes => {
          const entry = new KalturaMediaEntry();
          entry.mediaType = KalturaService.convertWondaToKalturaType(type);
          entry.name = file.name;
          entry.description = 'uploaded from Wonda VR Spaces';
          return this._kaltura.request(new MediaAddAction({ entry })).pipe(
            switchMap(addMediaRes => {
              const entryId = addMediaRes.id;
              const resource = new KalturaUploadedFileTokenResource();
              resource.token = uploadTokenRes.id;
              return this._kaltura.request(
                new MediaAddContentAction({ entryId, resource })
              );
            })
          );
        }),
        switchMap(addedEntry => {
          const entryId = addedEntry.id;
          const mediaEntry = new KalturaMediaEntry();
          mediaEntry.tags = '360';
          if (type === AssetType.v360 || type === AssetType.i360) {
            return this._kaltura.request(
              new MediaUpdateAction({ entryId, mediaEntry })
            );
          }
          return of(addedEntry);
        })
      );
  }
  public createKalturaMedia(asset: Asset, hub_id: string, course_id: string = null): Observable<Asset> {
    const params: any = {
      asset: asset
    };
    if (course_id) {
      params.course_id = course_id;
    }
    params.hub_id = hub_id;
    return this._http.post<Asset>(
      environment.coursesApiUrl + '/api/kaltura/medias',
      params
    );
  }

  public checkBackendConfig(hubId: string) {
    return this._http.get<any>(`${environment.coursesApiUrl}/api/hubs/${hubId}/kaltura/checkConfig`);
  }
}
