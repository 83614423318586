<div class="search-domain">
  <div class="search-domain-body">
    <h4>Search for your Organization Hub</h4>
    <div>
      <div class="input-label">Enter your Enterprise email address</div>
      <input class="grey-border" [ngClass]="{ 'error-red-border': noHubFound }" #emailInput matInput type="email" placeholder="Ex. alice@mail.com"
        (change)=resetSearch()>
      <mat-error *ngIf="noHubFound">
        No Hub found for this address
      </mat-error>
      <mat-error *ngIf="otherError">
        Oops something went wrong.<br/>
        Please try again or <a href="mailto:support@wondavr.com">contact us</a>
      </mat-error>
    </div>
    <div class="hub-list" *ngIf="hubList">
      <div class="input-label">Select the Hub you want to access:</div>
      <div class="hub-select grey-border">
        <mat-select placeholder="Select a Hub" [(value)]="selectedHubId" defaultTabIndex="0">
          <ng-container *ngFor="let hub of hubList">
            <mat-option [value]="hub.id"> {{ hub.title }}</mat-option>
          </ng-container>
        </mat-select>
      </div>
    </div>
    <div class="bottom-buttons">
      <button (click)="getSsoAndLog()" mat-raised-button color="primary">
        Continue
      </button>
      <button matStepperPrevious mat-button>
        {{ goBackMessage }}
      </button>
    </div>
  </div>
</div>