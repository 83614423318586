import { NgModule } from '@angular/core';
import { ContentModule } from '@app/layout/components/content/content.module';
import { UserMenuModule } from '@app/layout/components/user-menu/user-menu.module';

@NgModule({
    imports: [
      ContentModule,
      UserMenuModule
    ],
    exports: [
      ContentModule,
      UserMenuModule
    ]
})
export class LayoutModule
{
}
