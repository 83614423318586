import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectorRef,
  Input
} from '@angular/core';
import {
  MatDialog
} from '@angular/material/dialog';
import {
  Asset,
  AssetProviderType,
  AssetData,
  AssetType
} from '@shared/models';
import { KalturaService } from '@shared/services/kaltura.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { CurrentContextService } from '@shared/services/currentContextService';
import { mergeMap, filter, map, catchError } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { CurrentHubService } from '@shared/services/currentHub.service';

@Component({
  selector: 'assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.scss', '../file-manager.component.scss']
})
export class AssetsListComponent implements OnInit {
  @Input() mode: string;
  @Input() type: AssetType;

  @Output() selected = new EventEmitter<Asset>();
  course_id: string;
  learnspace_id: string;
  user_id: string;
  medias: Asset[] = [];
  kalturaNotConnected = false;
  searchForm: FormGroup;
  streamType: string = 'vod';

  constructor(
    private _dialog: MatDialog,
    private _courseApiService: CoursesAPIService,
    private _authenticationService: AuthenticationService,
    private _kalturaService: KalturaService,
    private currentContext: CurrentContextService,
    private _currentHub: CurrentHubService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.course_id = this.currentContext.currentCourse;
    this.learnspace_id = this.currentContext.currentLearnspace;
    this.loadAssets();

    this.searchForm = new FormGroup({
      streamTypeForm: new FormControl(''),
      textSearch: new FormControl('')
    });


    this.searchForm.valueChanges.subscribe(value => {
      this.streamType = value.streamTypeForm;
      this.loadAssets();
    });
  }

  loadAssets(mode?: string): void {
    const mediaToShow = mode ? mode : this.mode;
    let mediaList: Observable<Asset[]>;
    // Get user media
    if (mediaToShow === 'u_media') {
      mediaList = this._courseApiService.getAssetsByType(this.type);
    } else if (mediaToShow === 'l_media') {
      mediaList = this._courseApiService.getLearnspaceAssetsByType(this.type, this.learnspace_id);
    }
    // Get course media
    else if (mediaToShow === 'c_media') {
      mediaList = this._courseApiService.getCourseAssetsByType(
        this.type,
        this.course_id
      );
    }
    // Get Kaltura media
    else if (mediaToShow === 'k_media') {
      mediaList = this._kalturaService.getFilteredAndFormattedMediaList(this.type, this.user_id, undefined, this.streamType).pipe(
        catchError(err => {
          this.kalturaNotConnected = true;
          throw err;
        })
      );
    }
    else if (mediaToShow === 'rpm_media') {
      const lsAssets = this._courseApiService.getLearnspaceAssetsByType(this.type, this.learnspace_id);
      const userAssets = this._courseApiService.getAssetsByType(this.type);

      // This is a temporary development and it should be deleted when the default asset subject is tackled
      mediaList = combineLatest([lsAssets, userAssets]).pipe(
        map(([learnspaceAssets, myMedias]) => {

          const combinedList = [...learnspaceAssets, ...myMedias];

          const mergedList = [];
          const foundMap = new Map();

          for (const media of combinedList) {
            if (!foundMap.has(media.id) && media.provider === AssetProviderType.readyPlayerMe){
              foundMap.set(media.id, true);
              mergedList.push(media);
            }
          }

          mergedList.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

          return mergedList;
        })
      );
    }

    mediaList.subscribe(
      next => {
        this.medias = next;
      },
      error => {
        console.error(error);
        this.medias = [];
      }
    );
  }
  onSelect(selection: Asset): void {
    if(!selection.id && !selection.path) {
      return;
    }

    if (this.mode === 'k_media') {
      this._kalturaService
        .createKalturaMedia(selection, this._currentHub.hub.id, this.course_id)
        .subscribe(media => this.selected.emit(media));
    } else {
      this.selected.emit(selection);
    }
  }

  ownAsset(asset: Asset): boolean {
    return this._authenticationService.wondaUser.id === asset.user_id;
  }

  isTranscoding(asset: Asset): boolean {
    return asset.provider === AssetProviderType.kaltura &&  //for now only for Kaltura
      (asset.type === AssetType.v2D || asset.type === AssetType.v360) &&
      asset.path == null
  }

  isFromKaltura(asset: Asset): boolean {
    return asset.provider === AssetProviderType.kaltura;
  }

  isLive(asset: Asset): boolean {
    return asset.properties && asset.properties.isLive;
  }

  deleteAsset(event: Event, asset: Asset): void {
    event.stopPropagation();
    this._courseApiService
      .checkMediaUse(asset.id)
      .pipe(
        mergeMap(assetUsed => {
          const media = AssetData.get(asset.type).typeName.toLowerCase();
          let hintMessage = '';
          let elementList = null;
          // if (assetUsed) {
          //   hintMessage = `This ${media} is used in this experience or in other(s).`;
          // }
          let additionalInformation = 'This media will disappear from all experiences using it and this action can’t be undone.';
          if ( asset.type === AssetType.r3D ) {
            additionalInformation = 'This 3D environment will disappear from all experiences using it and this action can’t be undone.'
          }
          return this._dialog
            .open(ConfirmationDialogComponent, {
              width: '400px',
              height: 'auto',
              data: {
                title: 'Delete media',
                type: media,
                hintText: hintMessage,
                elementList: elementList,
                additionalInformation: additionalInformation
              }
            })
            .afterClosed();
        }),
        filter(val => val === 'ok'),
        mergeMap(a => {
          return this._courseApiService.deleteMedia(asset.id);
        })
      )
      .subscribe(() => this.loadAssets());
  }

  fullTextSearch(text: string): void {
    this._kalturaService.getFilteredAndFormattedMediaList(this.type, this.user_id, text, this.streamType).subscribe(list => {
      this.medias = list;
    });
  }
}
