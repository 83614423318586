import {AssetType} from '@shared/models/assetType.model';

export enum SceneType {
  i2D = '2D-Image',
  v2D = '2D-Video',
  i360 = '360-Image',
  v360 = '360-Video'
}

export const SceneTypeName = new Map<string, string>([
  [SceneType.i2D, 'Image'],
  [SceneType.v2D, 'Video'],
  [SceneType.i360, '360° Image'],
  [SceneType.v360, '360° Video']
]);

export const sceneTypeToAssetType = function(sceneType: SceneType) {
  switch (sceneType) {
    case SceneType.i2D:
      return AssetType.i2D;
    case SceneType.i360:
      return AssetType.i360;
    case SceneType.v2D:
      return AssetType.v2D;
    case SceneType.v360:
      return AssetType.v360;
  }
};
