import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { LoginComponent } from '@app/main/login/login.component';
import { SignUpComponent } from '@app/main/login/signUp.component';
import { PasswordResetComponent } from '@app/main/login/password-reset/password-reset.component';
import { SharedModule } from '@shared/shared.module';
import { UnAuthGuard } from '@app/guards/unauth.guard';
import { SearchHubByDomainComponent } from '@app/main/login/search-hub-by-domain/search-hub-by-domain.component';


const routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnAuthGuard]
  }, {
    path: 'signup',
    component: SignUpComponent,
    canActivate: [UnAuthGuard]
  }
];

@NgModule({
  declarations: [
    LoginComponent,
    SignUpComponent,
    PasswordResetComponent,
    SearchHubByDomainComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    HttpModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule
  ],
  entryComponents: [PasswordResetComponent],
  exports: [LoginComponent],
  providers: []
})
export class LoginModule {
}
