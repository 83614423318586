import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { UploadOutput, UploadFile, UploadInput, UploaderOptions } from 'ngx-uploader';
import { UploadService } from '@shared/services/upload.service';
import { Asset, AssetType, AssetData } from '@shared/models';

@Component({
  selector: 'file-dropper',
  templateUrl: './file-dropper.component.html',
  styleUrls: ['./file-dropper.component.scss']
})
export class FileDropperComponent implements OnInit {
  @ViewChild('canvasForThumbnail', { static: false }) canvasRef: ElementRef;

  @Input() imgPath: string;
  @Output() assetUploaded = new EventEmitter<string>();

  files: UploadFile[];
  dragOver: boolean;
  showSpinner: boolean;
  showUploaded: boolean;
  user_id: string;
  imgSrc: string;
  rejectedFileErrorMsg: string = null;
  uploadInput: EventEmitter<UploadInput>;
  options: UploaderOptions;
  acceptedExtensions: string[];

  constructor(
    private _uploadService: UploadService,
  ) {
  }

  ngOnInit(): void {
    this.files = [];
    this.showSpinner = false;
    this.imgSrc = this.imgPath;
    this.acceptedExtensions = AssetData.get(AssetType.i2D).mediaExtensions; //TODO generalize if other upload than 2D image
    this.options = {
      concurrency: 1,
      allowedContentTypes: this.acceptedExtensions
    };
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      this.startUpload();
      this.showSpinner = true;
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
    else if (output.type === 'rejected') {
      const r = `Please upload a compatible format (${this.acceptedExtensions.map(
        format => format.split('/').pop()
      )})`;
      this.rejectFile(r);
    }
  }

  /**
   * Process file : validate format, create asset, upload to storage, create thumbnail 
   * @param file 
   */
  private async processFile(file: File): Promise<void> {
    const metadata = await this._uploadService.getMediaMetadata(file);
    const formatValidation = this._uploadService.validateUpload(metadata);
    if (!formatValidation) {
      this.rejectFile('Please resize your media (maximum size is 8,640 x 4,320 px)');
      return;
    }
    const asset = new Asset(
      AssetType.i2D, //TODO generalize if other upload than 2D image
      'image desc',
      undefined,
      file.name
    );
    asset["properties"] = { originalHeight: metadata.height.toString(), originalWidth: metadata.width.toString() }; //TODO generalize if other upload than 2D image
    this._uploadService
      .createAndUploadAsset(asset, file)
      .subscribe(a => {
        this.assetUploaded.emit(a.id);
        this._uploadService.generateThumbnail(
          file,
          a,
          this.canvasRef
        );
        this.showSpinner = false;
        this.imgSrc = a.path;
      });
  }

  private async startUpload(): Promise<void> {
    this.files.forEach(async file => {
        await this.processFile(file.nativeFile);
    });
  }

  private rejectFile(reason: string, cleanList?: boolean, duration?: number) {
    const d = duration || 4000;
    this.rejectedFileErrorMsg = reason;
    if (cleanList) {
      this.files = [];
    }
    setTimeout(() => {
      this.rejectedFileErrorMsg = null;
    }, d);
  }
}
