import { Injectable } from '@angular/core';

@Injectable()
export class CurrentContextService {
  courseId = '';
  learnspaceId = '';
  get currentLearnspace(): string {
    return this.learnspaceId;
  }

  set currentLearnspace(id: string) {
    this.learnspaceId = id;
  }

  set currentCourse(id: string) {
    this.courseId = id;
  }
  get currentCourse(): string {
    return this.courseId;
  }
}
