import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Hub } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { environment } from '@env/environment';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { InternalAnalyticsService } from '@shared/services/internalAnalytics.service';
import { isArray } from 'util';

interface tagInputType { value: string, display: string };

@Component({
  selector: 'sso-config',
  templateUrl: './sso-config.component.html',
  styleUrls: ['./sso-config.component.scss']
})
export class SsoConfigComponent implements OnInit {

  private hubId: string;
  ssoIdpForm: FormGroup;
  metadataLink: string
  feedback: string;
  warning: string;

  constructor(
    private _courseService: CoursesAPIService,
    private _currentHub: CurrentHubService,
    private _internalAnalyticsService: InternalAnalyticsService
  ) {
    this.ssoIdpForm = new FormGroup({
      loginUrl: new FormControl('', { validators: Validators.required }),
      logoutUrl: new FormControl(''),
      certificate: new FormControl('', { validators: Validators.required }),
      allowedDomains: new FormControl('', { validators: [Validators.required] })
    })
  }

  ngOnInit(): void {
    this._currentHub.hubObservable.subscribe(hub => {
      if(hub.properties) {
        this.ssoIdpForm.reset(hub.properties.sso);
      } else {
        this.ssoIdpForm.reset({})
      }
      if(this.hubId !== hub.id) {
        this.feedback = null;
        this.warning = null;
      }
      this.hubId = hub.id;
      this.metadataLink = `${environment.coursesApiUrl}/api/auth/saml/${this.hubId}`;
    });
  }

  updateSSO(): void {
    let formattedDomainList;
    const allowedDomains = this.ssoIdpForm.value.allowedDomains;
    if(isArray(allowedDomains) && allowedDomains.length > 0) {
      formattedDomainList = this.ssoIdpForm.value.allowedDomains.map((domain: string | tagInputType ) => {
        if (typeof domain === "string") {
          return domain;
        } else {
          return domain.value;
        }
      });
    } else {
      this.warning = "NO_DOMAIN";
    }

    const updateObj = {
      loginUrl: this.ssoIdpForm.value.loginUrl,
      logoutUrl: this.ssoIdpForm.value.logoutUrl,
      certificate: this.ssoIdpForm.value.certificate,
      allowedDomains: formattedDomainList
    }
    this._courseService.updateHubSSOConfig(this.hubId, updateObj).subscribe(hub => {
      this.feedback = "OK";
      this._currentHub.setCurrentHub(hub);
    }, error => { 
      this.feedback = "KO";
    });
  }

  contactUs(): void {
    this._internalAnalyticsService.showNewMessage();
  }

}
