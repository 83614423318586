import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'durationConverter' })
export class DurationConverter implements PipeTransform {
  transform(duration_seconds: number, format: string = 'mm:ss'): string {
    if(isNaN(duration_seconds)){
      return "0 min";
    }
    const hours   = Math.floor(duration_seconds / 3600);
    const minutes = Math.floor((duration_seconds - (hours * 3600)) / 60);
    const minutes_rounded = Math.round((duration_seconds - (hours * 3600)) / 60);
    const seconds = duration_seconds - (hours * 3600) - (minutes * 60);
    if(format === "mm"){
      return minutes + ' min';
    }
    if(format === "hh:mm"){
      return (hours > 0) ? `${hours} h` : `${minutes_rounded} min`; 
    }
    return (hours > 0) ? `${hours} h` : '' + (minutes > 0) ? `${minutes} min` : '' + `${seconds} s`

  }
}
