<div class="searchInput">
  <img matPrefix class="ibmImg" src="assets/ibm/ibm-logo-white.png">
  <input class="idInput" #textSearch matInput placeholder="Insert the video or channel id here" (paste)="onPaste($event)">
  <mat-radio-group class="streamingTypeSelector" [(ngModel)]="streamingType">
    <mat-radio-button class="mat-caption" value="live">
      Live
    </mat-radio-button>
    <mat-radio-button class="mat-caption" value="vod">
      VOD
    </mat-radio-button>
  </mat-radio-group>
  <mat-icon *ngIf="!isLoading; else loading;" matSuffix (click)="IBMSearch(textSearch.value)" style="margin-bottom: 12px">search</mat-icon>
  <ng-template #loading>
    <mat-spinner matSuffix [diameter]=15></mat-spinner>
  </ng-template>
</div>
<ng-container *ngIf="currentVideo">
  <div *ngIf="!currentVideo.error; else notFound;" class="current-video">
    <div class="video-data-container">
      <div class="video-thumb">
        <img [src]="currentVideo.thumbnail">
      </div>
      <div class="video-texts">
        <div class="video-title">
          {{currentVideo.name}}
        </div>
        <div class="video-description">
          {{currentVideo.description}}
        </div>
      </div>
    </div>
    <div class="add-video-container">
      <button mat-raised-button (click)="selectVideo(currentVideo)">
        Add this video to your experience
      </button>
    </div>
  </div>
  <ng-template #notFound>
    <div class="video-not-found">No video found</div>
  </ng-template>
</ng-container>