<div class="medias-wrapper">
  <div *ngIf="mode==='k_media'">
    <div *ngIf="kalturaNotConnected;else kalturaSearch" class="kaltura-not-connected-msg">
      <img style="height: 20px; object-fit:contain;" src="assets/kaltura/kaltura-logo-small.png">
      <p class="mat-caption">
        You have not linked your account to your Kaltura library. Complete your account settings or
        <a href="mailto:support@wondavr.com">contact us
        </a>.
      </p>
    </div>
    <ng-template #kalturaSearch>
      <form class="search-form" [formGroup]="searchForm">
        <mat-form-field class="search-input">
          <img matPrefix style="height: 28px; padding: 4px; object-fit:contain;" src="assets/kaltura/kaltura-logo-small.png">
          <input #textSearch matInput placeholder="Search your media by name or by ID">
          <mat-icon matSuffix (click)="fullTextSearch(textSearch.value)">search</mat-icon>
        </mat-form-field>
        <mat-radio-group class="stream-type-option-group" *ngIf="this.type === '2D-Video' || this.type === '360-Video'" formControlName="streamTypeForm">
          <mat-radio-button checked class="mat-caption" value="vod">
            VOD
          </mat-radio-button>
          <mat-radio-button class="mat-caption" value="live">
            Live (beta)
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </ng-template>
  </div>
  <div class="medias-body">
    <div fxLayout="row wrap">
      <div #displayedItem *ngFor="let media of medias" class="asset-preview" (click)="onSelect(media)">
        <mat-icon *ngIf="ownAsset(media) && course_id" class="asset-delete-button" (click)="deleteAsset($event, media)">delete</mat-icon>
        <ng-container *ngIf="isFromKaltura(media)">
          <img src="assets/kaltura/kaltura-logo-small.png" class="asset-kaltura-label" />
        </ng-container>
        <ng-container *ngIf="isLive(media)">
          <mat-icon class="asset-live-label">cell_tower</mat-icon>
        </ng-container>
        <div class="thumbnail-container">
          <div *ngIf="isTranscoding(media)" class="asset-transco-msg">
            <mat-icon>hourglass_full</mat-icon>
            Transcoding video
          </div>
          <img *ngIf="media.thumbnail; else mediaName" [src]="media.thumbnail">
          <ng-template #mediaName>
            <mat-icon *ngIf="media.type=='sound'" class="secondary-text">music_video</mat-icon>
          </ng-template>
        </div>
        <div class="mat-caption thumbnailName" [matTooltip]="media.name" [matTooltipShowDelay]="0">{{ media.name }}</div>
      </div>
    </div>
  </div>
</div>