<div class="mat-card">
  <h3>{{ data.title }}</h3>
  <div>
    <p *ngIf="data.hintText">{{ data.hintText }}</p>
    <ul *ngIf="data.elementList">
      <li *ngFor="let elem of data.elementList">{{ elem }}</li>
    </ul>
    <p>Are you sure you want to {{ action }} this {{ data.type }} ? {{data.additionalInformation}}</p>
    <p *ngIf="data.ps" class="ps">{{ data.ps }}</p>
  </div>
  <div class="actions">
    <button mat-button color="warn" [mat-dialog-close] >CANCEL</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="'ok'" cdkFocusInitial>OK</button>
  </div>
</div>