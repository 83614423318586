import { NgModule } from '@angular/core';
import { HubEditComponent } from '@app/main/hub/hub-edit/hub-edit.component';
import { SharedModule } from '@shared/shared.module';
import { SettingsModule } from '@app/main/settings/settings.module';

@NgModule({
  declarations: [
    HubEditComponent
  ],
  imports: [
    SharedModule,
    SettingsModule
  ],
  entryComponents: [],
})
export class HubEditorModule { }
