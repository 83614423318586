export class AnnotationAdditionalData {
  public animations: Array<AnnotationAnimationData>;
  public stats: any;
  constructor(
  ) {}

  static fromRoomEditorData(data: any): AnnotationAdditionalData {
    if (!data) {
      return;
    }

    const annotationAdditionalData = new AnnotationAdditionalData();
    if (data.animations && data.animations.length) {
      annotationAdditionalData.animations = data.animations.map(AnnotationAnimationData.fromRoomEditorData);
    }

    if (data.stats) {
      annotationAdditionalData.stats = data.stats;
    }

    return annotationAdditionalData;
  }
}

export class AnnotationAnimationData {
    constructor(
      public name: string,
      public duration: number
    ) {}

    static fromRoomEditorData(animationData: any): AnnotationAnimationData {
      return new AnnotationAnimationData(animationData.name, animationData.duration);
    }
}

