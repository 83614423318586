import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  User,
  Learnspace,
  Invitation,
  Course,
  isAdmin,
  isInOtherlab,
  isInImlab,
  getHubListAndRole
} from '@shared/models';
import { Router } from '@angular/router';

@Injectable()
export class InternalAnalyticsService {
  constructor(private _router: Router) { }

  /**
   * Intercom is an analytics and communication tool to track user activity
   */
  private isIntercomDisabled(): boolean {
    return (<any>window).Intercom === undefined;
  }

  public initIntercom(): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('boot', {
      app_id: environment.intercom_app_id
    });
  }

  public initIntercomWithUser(user: User): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    const intercomParams: any = {
      app_id: environment.intercom_app_id,
      name: user.first_name,
      email: user.email,
      user_hash: user.intercom_hash,
      admin: isAdmin(user),
      other_hub: isInOtherlab(user),
      imlab_hub: isInImlab(user),
      hide_default_launcher: false
    };

    (<any>window).Intercom('boot', intercomParams);
  }

  public identifyHeapUser(user: User): void {
    if ((<any>window).heap === undefined) return;
    (<any>window).heap.identify(user.id);
    let mainHub = user.Hubs[0];
    if (user.preferences.defaultHub) {
      mainHub = user.Hubs.find(hub => hub.id === user.preferences.defaultHub);
    }
    const customProperties = {
      admin: isAdmin(user),
      hubCount: user.Hubs.length,
      name: mainHub.urlTitle,
      plan: mainHub.plan,
      hubType: mainHub.hub_type
    };
    (<any>window).heap.addUserProperties(customProperties);

  }
  public hideChat(): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('update', {
      hide_default_launcher: true
    });
  }

  public showChat(): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('update', {
      hide_default_launcher: false
    });
  }

  public showNewMessage(): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('showNewMessage');
  }
  public disconnectIntercom(): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('shutdown');
  }

  public trackCourseCreation(course: Course, templateId: string, templateTitle: string): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('trackEvent', 'create-course', {
      id: course.id,
      title: course.title,
      template_id: templateId,
      template_title: templateTitle
    });
  }

  public trackCourseEdition(course: Course): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('trackEvent', 'edit-course', {
      id: course.id,
      title: course.title
    });
  }
  public trackLSCreation(ls: Learnspace): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('trackEvent', 'create-learnspace', {
      id: ls.id,
      title: ls.title
    });
  }

  public trackInvitation(invitation: Invitation): void {
    if (this.isIntercomDisabled()) {
      return;
    }
    (<any>window).Intercom('trackEvent', 'invite-to-learnspace', {
      id: invitation.id,
      recipient: invitation.recipient_mail,
      learnspace_id: invitation.target_id
    });
  }
}
