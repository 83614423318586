<div class="tab-body">
    <div class="hub-selection">
        <div style="width: 150px">Select hub</div>
        <mat-select
          [placeholder]="hubs[0].title"
          (selectionChange)="getHubInfo($event)"
        >
          <ng-container *ngFor="let hub of hubs">
            <mat-option [value]="hub.id">{{ hub.title }}</mat-option>
          </ng-container>
        </mat-select>
      </div>
      <mat-tab-group class="integration-tabs">
        <!-- <mat-tab label="LTI">
            LTI still in account settings
          </mat-tab> -->
        <mat-tab label="IBM Cloud">
          <div class="tab-content">
            <div class="tab-content-header">
              <img src="assets/ibm/ibm-logo-black.png"/>
            </div>
            <div *ngIf="hubIntegrations?.ibm; else noIbm" style="color:green">
              IBM Cloud is activated
            </div>
            <ng-template #noIbm>
              <div class="error-msg">
                IBM Cloud is not activated
              </div>
            </ng-template>
            <!-- See here how to use IBM videos in Wonda VR Spaces.<br/> -->
            <div>
                <a href="mailto:support@wondavr.com">Contact us</a> to get information about IBM Video integration.
            </div>
          
          </div>
        </mat-tab>
        <mat-tab label="Kaltura">
          <div class="tab-content">
            <div class="tab-content-header">
              <img src="assets/kaltura/kaltura-logo.png"/>
              <mat-icon (click)="goToHelp('http://help.spaces.wondavr.com/en/articles/2630822-kaltura-video-integration')" class="help-link">help</mat-icon>
            </div>
            <div *ngIf="hubIntegrations?.kaltura; else noKaltura" style="color:green">
              Kaltura is activated
            </div>
            <ng-template #noKaltura>
              <div class="error-msg">
                Kaltura is not activated
              </div>
            </ng-template>
            <!-- See here how to use Kaltura videos in Wonda VR Spaces.<br/> -->
            <div>
                <a href="mailto:support@wondavr.com">Contact us</a> to get information about Kaltura Video Platform.
            </div>
          </div>
        </mat-tab>
        <mat-tab label="LTI">
          <div class="tab-content">
            <div *ngIf="hubIntegrations?.lti_enabled; else noLTI" style="color:green">
              LTI is activated
            </div>
            <ng-template #noLTI>
              <div class="error-msg">
                LTI has not been activated by the hub administrator.
              </div>
            </ng-template>
            <div>
                <a href="mailto:support@wondavr.com">Contact us</a> to get information about LMS integration.
            </div>
          
          </div>
        </mat-tab>
      </mat-tab-group>
      
</div>
