<div fxLayout="column" fxLayoutAlign="space-evenly center" class="wrapper learnspace-theme">
  <ng-container *ngIf="error === 'NO_ASSOCIATED_HUB'; else usualError">
    <div>
      <div class="header">
        <div>Welcome to</div>
        <div class="logo">
          <img src="assets/wonda/wonda-vr-logo-retina.png">
        </div>
      </div>
      <div class="no-associated-hub-error">
        <p>It seems that you are not linked to a hub<br></p>
        <button mat-button (click)="joinImlab()">Click here to join the Immersive lab</button>
      </div>
    </div>
  </ng-container>
  <ng-template #usualError>
    <div class="invitation-content" fxLayout="column" fxLayoutAlign="center center">
      <p id="error">{{ error }}</p>
    </div>
  </ng-template>
</div>