import { AfterViewInit, Directive, ElementRef, Input, OnChanges, ViewContainerRef, NgZone, Inject, Optional } from '@angular/core';
import { CurrentHubService } from '@shared/services/currentHub.service';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MatTooltip,
  MatTooltipDefaultOptions
} from '@angular/material/tooltip';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';

@Directive({
  selector: '[plan-limit]'
})
export class PlanLimitDirective extends MatTooltip implements AfterViewInit, OnChanges {
  @Input() customTooltip: string;
  @Input() canDo: boolean;

  constructor(
    _overlay: Overlay,
    _elementRef: ElementRef,
    _scrollDispatcher: ScrollDispatcher,
    _viewContainerRef: ViewContainerRef,
    _ngZone: NgZone,
    _platform: Platform,
    _ariaDescriber: AriaDescriber,
    _focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) _scrollStrategy: any,
    @Optional() _dir: Directionality,
    @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
    _defaultOptions: MatTooltipDefaultOptions,
    private el: ElementRef,
    private currentHub: CurrentHubService
  ) {
    super(
      _overlay,
      _elementRef,
      _scrollDispatcher,
      _viewContainerRef,
      _ngZone,
      _platform,
      _ariaDescriber,
      _focusMonitor,
      _scrollStrategy,
      _dir,
      _defaultOptions
    );
  }

  ngAfterViewInit() {
    this.toggleDisable();
    super.ngAfterViewInit();
  }

  ngOnChanges(changes: any) {
    if (changes.canDo.previousValue !== changes.canDo.currentValue) {
      this.toggleDisable();
    }
  }

  toggleDisable() {
    if (this.canDo === false) {
      this.applyDisable();
    } else if (this.canDo === undefined && this.currentHub.plan && !this.currentHub.plan.isPaid) {
      this.applyDisable();
    } else {
      this.removeDisable();
    }
  }

  applyDisable() {
    this.el.nativeElement.classList.add("disabled");
    if(this.el.nativeElement.tagName === "BUTTON") {
      this.el.nativeElement.classList.add("mat-button-disabled");
    } else {
      this.el.nativeElement.classList.add("plan-limit-disabled");
    }
    this.message = this.customTooltip ? this.customTooltip : 'Upgrade to use this functionality';
  }

  removeDisable() {
    this.el.nativeElement.classList.remove("disabled");
    if(this.el.nativeElement.tagName === "BUTTON") {
      this.el.nativeElement.classList.remove("mat-button-disabled");
    } else {
      this.el.nativeElement.classList.remove("plan-limit-disabled");
    }
    this.message = '';
  }
}
