import { Injectable } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
export enum Icons {
    Cardboard = 'cardboard',
    Content360 = 'content-360',
    Analytics = 'analytics',
    AddParticipant = 'add-participant',
    AddImage = 'add-image',
    AddVideo = 'add-video',
    AddText = 'add-text',
    AddHotspot = 'add-hotspot',
    AddCard = 'add-card',
    AddScorecard = 'add-scorecard',
    AddQuiz = 'add-quiz',
    Add3D = 'add-3D',
    AddSnapZone = 'add-snap-zone',
    AddNPC = 'add-npc',
    PerformanceWarning = 'performance-warning',
    PerformanceError = 'performance-error',
    PerformanceOk = 'performance-ok',
    IconActionLink = 'icon-action-link',
    IconActionToggle = 'icon-action-toggle',
    IconActionSound = 'icon-action-sound',
    MagicHand = 'magichand',
    IconDownload = 'download'
}

@Injectable({
    providedIn: 'root'
  })
  export class SvgIconService {
  
    constructor(
      private matIconRegistry: MatIconRegistry,
      private domSanitizer: DomSanitizer
    ) { }
  
    public registerIcons(): void {
      this.loadIcons(Object.values(Icons), '../assets/svg');
    }
  
    private loadIcons(iconKeys: string[], iconUrl: string): void {
      iconKeys.forEach(key => {
        this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
      });
    }
  }
  
