import { Injectable } from '@angular/core';
// @ts-ignore
import { FileUtils, WebIO } from '@gltf-transform/core';
import { KHRONOS_EXTENSIONS } from '@gltf-transform/extensions';
import { unzipSync } from 'fflate';

export function getBaseName(assetName: string): string {
    return assetName.split('.' + FileUtils.extension(assetName))[0];
}

@Injectable()
export class GlbService {
    constructor(
    ) {}

    public async zipToGlb(file: File): Promise<File> {
        const fileContent = await readFile(file);
        const zip = unzipSync(new Uint8Array(fileContent));
        if (!zip) {
            throw new Error('glb.services::fromZipToGlb - Error loading the zip file');
        }

        const fileNames = Object.keys(zip);
        const fileExtensions = fileNames.map(FileUtils.extension);

        const gltfFileCount = fileExtensions.filter(extension => extension === 'gltf' || extension === 'glb').length;
        if (gltfFileCount !== 1) {
            throw new Error('glb.services - Unexpected number of gltf file in the archive');
        }
        const glbFileName = fileNames.find(fileName => FileUtils.extension(fileName) === 'glb');
        if (glbFileName) {
            const blob = new Blob([zip[glbFileName]], { type: 'application/octet-stream' });
            return new File([blob], getBaseName(file.name) + '.glb', { type: 'application/octet-stream' });
        }

        const gltfJSONFileName = fileNames.find(fileName => FileUtils.extension(fileName) === 'gltf');
        if (!gltfJSONFileName) {
            throw new Error('glb.services - the gltf filename should be defined');
        }

        const json = JSON.parse(new TextDecoder().decode(zip[gltfJSONFileName]));
        const jsonDocument = {
            json: json,
            resources: zip
        };

        const webIO = new WebIO().registerExtensions(KHRONOS_EXTENSIONS);
        const document = await webIO.readJSON(jsonDocument);
        const arrayBuffer = await webIO.writeBinary(document);

        const glbBlob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
        return new File([glbBlob], getBaseName(file.name) + '.glb', { type: 'application/octet-stream' });
    }

    public async gltfToGlb(files: Array<File>): Promise<File> {
        const gltfJSONFile = files.find(file => FileUtils.extension(file.name) === 'gltf');
        if (!gltfJSONFile) {
            throw new Error('glb.services::fromZipToGlb - the gltf file is not found');
        }

        const json = JSON.parse(new TextDecoder().decode(await gltfJSONFile.arrayBuffer()));
        const resources: any = {};
        const fileCount = files.length;
        for (let i = 0; i < fileCount; i++) {
            const cFile = files[i];
            resources[cFile.name] = new Uint8Array(await cFile.arrayBuffer());
        }

        const jsonDocument = {
            json: json,
            resources: resources
        };

        const webIO = new WebIO().registerExtensions(KHRONOS_EXTENSIONS);
        const document = await webIO.readJSON(jsonDocument);
        const arrayBuffer = await webIO.writeBinary(document);

        const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
        return new File([blob], getBaseName(gltfJSONFile.name) + '.glb', { type: 'application/octet-stream' });
    }
}

async function readFile(zipFile: File): Promise<ArrayBuffer> {
    const reader = new FileReader();
    return new Promise(resolve => {
        reader.addEventListener(
          'load',
          function(event: ProgressEvent<FileReader>): void {
              // @ts-ignore
              return resolve(event.target.result);
          },
          false
        );
        reader.readAsArrayBuffer(zipFile);
    });
}
