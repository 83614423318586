import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Learnspace, InvitationType } from '@shared/models';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, filter } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'add-participant',
  templateUrl: './add-participant-dialog.component.html',
  styleUrls: ['./add-participant-dialog.component.scss' ]// , './learnspace-detail.component.scss']
})
export class AddParticipantDialogComponent implements OnInit {
  public emailForm: FormGroup;
  public learnspace: Learnspace;
  public invitationType: InvitationType = InvitationType.joinLearnspace;
  constructor(
    private _authservice: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _courseApiService: CoursesAPIService,
    public dialogRef: MatDialogRef<AddParticipantDialogComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { learnspace: Learnspace, user_id: string }
  ) {
    this.emailForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.learnspace = this.data.learnspace;
    if(!this.learnspace.Participants) {
      this._courseApiService.getParticipants(this.learnspace.id).subscribe(
        res => {
          this.learnspace.Participants = res;
        }
      )
    }
  }

  isOwner(participant: any): boolean {
    return this.learnspace.owner_id === participant.id;
  }
  canKick(): boolean {
    return this.learnspace.owner_id === this._authservice.wondaUser.id;
  }

  canInvite(): boolean  {
    return this.learnspace.status !== "archived";
  }
  
  kickUser(userId: string): void {
    this._dialog
    .open(ConfirmationDialogComponent, {
      width: '25vw',
      height: '28vh',
      panelClass: 'learnspace-theme',
      data: {
        title: 'Unsubscribe this user',
        type: 'user',
        action: 'unsubscribe'
      }
    })
    .afterClosed()
    .pipe(
      filter(val => val === 'ok'),
      switchMap(a => {
        return this._courseApiService.unsubscribeLearnspace(this.learnspace.id, userId);
      })
    )
    .subscribe(() => {
      this.learnspace.Participants = this.learnspace.Participants.filter((u) => {
        return u.id !== userId;
      });
    });

  }
}
