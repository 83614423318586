<mat-tab-group>
  <mat-tab label="LTI">
    <ng-template matTabContent>
      <div *ngIf="cannotSetLTI" class="disabled-overlay text-container">
        <p class="center-text darkblue-text"> {{ cannotEditMessage }}
          <br>
          <span class="sales-button" (click)="contactSales()">
            Please upgrade your account to enable them.
          </span>
        </p>
      </div>
      <lti-credentials [hubId]="hub.id"></lti-credentials>
    </ng-template>
  </mat-tab>
  <mat-tab label="SSO">
    <ng-template matTabContent>
      <sso-config></sso-config>
      <div *ngIf="cannotSetSSO" class="disabled-overlay text-container">
        <p class="center-text darkblue-text"> {{ cannotEditMessage }}
          <br>
          <span class="sales-button" (click)="contactSales()">
            Please upgrade your account to enable them.
          </span>
        </p>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Kaltura">
    <ng-template matTabContent>
      <div *ngIf="cannotSetIntegrations" class="disabled-overlay text-container">
        <p class="center-text darkblue-text"> {{ cannotEditMessage }}
          <br>
          <span class="sales-button" (click)="contactSales()">
            Please upgrade your account to enable them.
          </span>
        </p>
      </div>
      <kaltura-account-form [hubId]="hub.id" (connectionChange)="resetConnections()"></kaltura-account-form>
    </ng-template>
  </mat-tab>
  <mat-tab label="IBM Cloud">
    <ng-template matTabContent>
      <ibm-account-form [hubId]="hub.id" (connectionChange)="resetConnections()"></ibm-account-form>
      <div *ngIf="cannotSetIntegrations" class="disabled-overlay text-container">
        <p class="center-text darkblue-text"> {{ cannotEditMessage }}
          <br>
          <span class="sales-button" (click)="contactSales()">
            Please upgrade your account to enable them.
          </span>
        </p>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>