import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { CoursesAPIService } from '@shared/services/coursesApi.service';
import { Hub } from '@shared/models/hub.model';
import { environment } from '@env/environment';
import { CurrentHubService } from '@shared/services/currentHub.service';
import { LTIPlatform } from '@shared/models';
import { LTICredentialsPlatformSettingsComponent } from './platfom-settings/lti-credentials-platfom-settings.component';

@Component({
  selector: 'lti-credentials',
  templateUrl: './lti-credentials.component.html',
  styleUrls: ['./lti-credentials.component.scss']
})

export class LTICredentialsComponent implements OnChanges {
  @Input() hubId: string;
  public hub: Hub;
  public backendURL: string;
  public ltiTableColumns = ['name', 'actions'];
  public lti_platforms?: LTIPlatform[];
  public canAddLTIPlatform: boolean;

  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  constructor(
    private _dialog: MatDialog,
    private _courseApiService: CoursesAPIService,
    private _currentHubService: CurrentHubService
  ) {
    this.backendURL = environment.coursesApiUrl;
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.hubId) {
      this._courseApiService.getLTICredentials(this.hubId).subscribe(hub => {
        this.hub = hub;
        this.getLTIPlatforms();
      });
    }
  }

  updateLTIStatus(event: any): void {
    if (event.checked) {
      this._courseApiService.enableLTI(this.hub.id).subscribe(hub => {
        this.hub = hub;
        this._currentHubService.hub.lti_enabled = true;
      });
    }
    else {
      this._courseApiService.disableLTI(this.hub.id).subscribe(hub => {
        this.hub = hub;
        this._currentHubService.hub.lti_enabled = false;
      });
    }
  }

  editPlatform(platform?: LTIPlatform): void {
    const sendInvitDialog = this._dialog.open(LTICredentialsPlatformSettingsComponent, {
      width: '40vw',
      height: 'auto',
      panelClass: 'learnspace-theme',
      data: {
        platform,
        commitChanges: this.updateLTICredentials.bind(this)
      }
    });
  }
  
  updateLTICredentials(platform: LTIPlatform, action: string): Promise<void> {
    let updatedPlatforms = this.lti_platforms || [];

    switch (action) {
      case 'add':
        updatedPlatforms.push(platform);
        break;
      case 'update':
        const index = updatedPlatforms.findIndex(existing => existing.clientId === platform.clientId);
        if (index >= 0) {
          updatedPlatforms[index] = platform;
        }
        break;
      case 'remove':
        updatedPlatforms = updatedPlatforms.filter(existing => existing.clientId != platform.clientId);
        break;
    }
    
    return new Promise((resolve, reject) => {
      const platforms = updatedPlatforms.length > 0 ? updatedPlatforms : undefined;
      this._courseApiService.updateLTIIntegration(this.hub.id, platforms)
        .subscribe(hub => {
          this.hub = hub;
          this.getLTIPlatforms();
          resolve();
        }, reason => {
          reject(reason);
        });
    });
  }

  openHelp(): void {
    window.open('https://help.spaces.wondavr.com/en/articles/2630787-lms-connectors');
  }

  getLTIButtonTooltip(): string {
    if (this.canAddLTIPlatform || this._currentHubService.plan.allowMultipleLTIPlatforms) {
      return '';
    } else {
      return 'Upgrade to the Publisher plan to add more LTI 1.3 integrations';
    }
  }

  private getLTIPlatforms(): void {
    this.lti_platforms = this.hub.properties?.lti_platforms;

    if (this._currentHubService.plan.allowMultipleLTIPlatforms) {
      this.canAddLTIPlatform = true;
    } else {
      this.canAddLTIPlatform = (this.lti_platforms?.length || 0) < 1;
    }
  }
}
