import {AnnotationAction, AnnotationType, Asset, AssetType, QuizChoicesProperties, QuizInputProperties, Style} from '@shared/models';

export class Annotation {
  constructor(
    public type: AnnotationType,
    public label: string,
    public id?: string,
    public properties?: {
      zindex?: number;
      textvalue?: string;
      src?: string;
      style?: Style;
      volume?: number;
      autoplay?: boolean;
      displayVideoControls?: boolean;
      loopVideo?: boolean;
      size?: any;
      position?: any;
      action?: AnnotationAction;
      loopingAnimationName?: string;
      title?: string;
      body?: string;
      hide?: boolean;
      locked?: boolean;
      custom_duration?: boolean;
      time_in?: number;
      duration?: number;
      quiz?: QuizChoicesProperties | QuizInputProperties;
      snapZoneId?: string;
    },
    public scene_id?: string,
    public media_id?: string,
    public media?: Asset,
    public media_type?: AssetType,
    public course_id?: string,
    public is_dynamic?: boolean
  ) {

  }

  clone(): Annotation {
      const clone = new Annotation(AnnotationType.card, '');
      return clone.copy(this);
  }

  copy(annotation: Annotation): Annotation {
      this.type = annotation.type;
      this.label = annotation.label;
      this.id = annotation.id;
      this.properties = deepCloneStreamable(annotation.properties);
      this.scene_id = annotation.scene_id;
      this.media_id = annotation.media_id;
      //for now media is not deeply cloned but this might cause issue
      this.media = annotation.media;
      this.media_type = annotation.media_type;
      this.course_id = annotation.course_id;
      this.is_dynamic = annotation.is_dynamic;
      return this;
  }
}

function deepCloneStreamable(streamableObj: object): object {
    return JSON.parse(JSON.stringify(streamableObj));
}
