<p class="mat-caption filter-hint">
  Add your live feed by adding your stream manifest url <mat-icon (click)="goToHelp()" class="help-link">help</mat-icon>
</p>
<div class="searchInput">
  <mat-form-field class="url-input">
    <mat-icon matPrefix>cell_tower</mat-icon>
    <input #textSearch matInput placeholder="Copy livestream url here (.m3u8 or .mpd)" (paste)="onPaste($event)">
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="validatePathAndCreateMedia(textSearch.value)">Add to
    library</button>
</div>
<ng-container *ngIf="!pathError; else urlError;">
  <div *ngIf="currentVideo" class="video-data-container">
    <div class="video-thumb" (click)="selectVideo(currentVideo)">
      <ng-container *ngIf="currentVideo.thumbnail; else noThumbnail;">
        <img [src]="currentVideo.thumbnail">
      </ng-container>
      <ng-template #noThumbnail>
        <img src="assets/img/live-icone.png">
      </ng-template>
    </div>
    <div class="mat-caption video-title">
      {{currentVideo.name}}
    </div>
  </div>
</ng-container>
<ng-template #urlError>
  <div class="path-error">There was an error when adding the live feed<br>{{pathError.message}}</div>
</ng-template>