<div fxLayout="column" fxLayoutAlign="center center" class="learnspace-theme">
  <div class="header">
    <img class="logo" src="assets/wonda/logo-wonda.png">
  </div>
  <div class="content-wrapper">
    <h3 class="title">Welcome!</h3>
    <h4>Let's set up your hub</h4>
    <form [formGroup]="hubForm" class="step-body-wrapper">
      <div>
        <mat-form-field>
          <input matInput placeholder="Name your Hub" formControlName="title">
        </mat-form-field>
        <mat-form-field>
          <div class="url-title-input">
            <span>spaces.wondavr.com/</span>
            <input matInput formControlName="urlTitle">
          </div>
          <mat-error *ngIf="hubForm.get('urlTitle').hasError('specialchars')">
            Your hub title cannot contain whitespace or special char (except - and _)
          </mat-error>
          <mat-error *ngIf="hubForm.get('urlTitle').hasError('reserved')">
            Unfortunately, this title is a reserved keyword and thus cannot be used
          </mat-error>
          <mat-error *ngIf="hubForm.get('urlTitle').hasError('checkUnicity')">
            this url is already taken
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Hub type</mat-label>
          <mat-select formControlName="hub_type">
            <mat-option value="enterprise">
              Enterprise
            </mat-option>
            <mat-option value="university">
              University
            </mat-option>
            <mat-option value="agency">
              Agency
            </mat-option>
            <mat-option value="personal">
              Personal
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p>Upload a logo</p>
        <div class="add-logo-button">
          <file-dropper (assetUploaded)="addLogoId($event)"></file-dropper>
          <span>We recommend transparent png</span>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="createHub()" [disabled]="hubForm.invalid">CREATE YOUR
        HUB
      </button>
      <div *ngIf="errorMessage" class="error-msg">{{ errorMessage }}</div>
    </form>
  </div>
</div>