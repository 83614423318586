import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from '@shared/services/authentication.service';
import { switchMap } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'sign-up',
  templateUrl: './signUp.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations
})
export class SignUpComponent implements OnInit {
  signUpForm: FormGroup;
  signUpError: string = null;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.signUpForm = this._formBuilder.group({
      first_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  createUser(): void {
    this._authService.createUser(this.signUpForm.value).pipe(
      switchMap(() => {
        return this._authService.mailLogin(this.signUpForm.controls.email.value, this.signUpForm.controls.password.value);
      })
    ).subscribe(null, err => {
      if(err.error && err.error.message) {
        this.signUpError = err.error.message;
      } else {
        this.signUpError = err.message;
      }
      this._changeDetector.detectChanges();
    });
  }
  public quickLogin(provider: string): void {
    this._authService.quickLogin(provider, true).subscribe(null, err => {
      this.signUpError = err.message
      this._changeDetector.detectChanges();
    });
  }

  public ssoFlow(stepper: MatStepper): void {
    stepper.next();
  }
}
